import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import API from '../utils/API';
import { mapMedicalStatsFromResponse, mapDentalStatsFromResponse } from '../utils/data';
import {
  GET_STATS_REQUEST,
  GET_STATS_REQUEST__SUCCESS,
  GET_STATS_REQUEST__FAILURE,
  GET_COMPANY_COUNT_REQUEST,
  GET_COMPANY_COUNT_REQUEST__SUCCESS
} from '../store/data';
import {
  closeModal
} from '../store/display';
import {
  addError
} from '../store/errors';
import {
  ErrorHandler
} from './user';

function makeQueryParams({benchmarks, client, insuranceType, states}){
  let params = {}
  if(benchmarks.regions.length > 0 && benchmarks.regions[0].type === "region_id"){
    params.region_ids = benchmarks.regions.map(region => region.value);
  } else if ((benchmarks.regions.length > 0 && benchmarks.regions[0].label !== "All" ) || benchmarks.markets.length > 0 ){
    if(benchmarks.regions.length > 0){
      params.state_ids = benchmarks.regions.map(state => state.value);
    } else {
      params.market_ids = benchmarks.markets.map(market => market.value);
    }
  } else {
    params.geo_all = true;
  }
  if(benchmarks.major_industry.length > 0 && benchmarks.major_industry[0].label !== "All"){
    params.major_industry_ids = benchmarks.major_industry.map(major_industry => major_industry.value);
  } else if (benchmarks.industry.length > 0){
    params.industry_ids = benchmarks.industry.map(industry => industry.value);
  } else if (benchmarks.minor_industry.length > 0){
    params.minor_industry_ids = benchmarks.minor_industry.map(minor_industry => minor_industry.value);
  } else {
    params.sic_all = true;
  }
  if(benchmarks.group.length > 0){
    params.groupsize_min = benchmarks.group[0].value.min_value ? Number(benchmarks.group[0].value.min_value) : 1;
    params.groupsize_max = benchmarks.group[0].value.max_value ? Number(benchmarks.group[0].value.max_value) : null;
  } else {
    params.groupsize_min = 1;
    params.groupsize_max = null;
  }
  if(benchmarks.filter.length > 0 && benchmarks.filter[0].label !== "All"){
    params.fund_ids = benchmarks.filter.map(filter => filter.value)
  }
  if(benchmarks.products.length > 0 && benchmarks.products[0].label !== "All"){
    params.product_ids = benchmarks.products.map(product => product.value)
  }
  if(client){
    if(isNaN(client.plans[0])){
      if(insuranceType === "medical"){
        params.quote_ids = client.plans.map(plan => plan.quote_id)
      } else {
        params.quote_dental_ids = client.plans.map(plan => plan.quote_dental_id)
      }
    } else {
      params.company_id = client.plans[0];
    }

  }

  return params;
}

const getQueryState = ({clients, display, benchmarks, options}) => ({insuranceType: display.insurance, benchmarks: benchmarks[display.insurance], client: clients.selection[display.insurance], states: options.states});


function* statsSaga({payload}){
  try {
    let queryState = yield select(getQueryState);
    let isMedical = queryState.insuranceType === 'medical';
    let url = isMedical ? `averages/query.json` : `averages/dental_query.json`;
    let params = makeQueryParams(queryState);
    let response = yield call(API.post, url, params);
    if(response.our_values.get_errors && response.our_values.get_errors.length > 0){
      throw response.our_values.get_errors.join(", ")
    }
    yield put({ type: GET_STATS_REQUEST__SUCCESS, payload: {insuranceType: queryState.insuranceType, data: isMedical ? mapMedicalStatsFromResponse(response, queryState.client) : mapDentalStatsFromResponse(response, queryState.client) }});
    if(payload.from){
      yield put(push('/dashboard'))
    }
  } catch(error) {
    let handled = yield call(ErrorHandler, error)
    if(!handled){
      yield put({ type: GET_STATS_REQUEST__FAILURE, payload: payload.insuranceType})
      yield put(addError((error && typeof error === "string") ? error : "Unable to complete request - please try again later."))
    }
  }
}

function* companyCountSaga(){
  try {
    let queryState = yield select(getQueryState);
    let url = queryState.insuranceType === 'medical' ? `averages/count.json` : `averages/dental_count.json`;
    let params = makeQueryParams(queryState);
    let response = yield call(API.post, url, params);
    yield put({type: GET_COMPANY_COUNT_REQUEST__SUCCESS, payload: {insuranceType: queryState.insuranceType, companies: response.companies.toLocaleString(), plans: response.plans.toLocaleString(), members: response.members.toLocaleString()}})
  } catch (e) {
    yield call(ErrorHandler, e)
  }

}

export default function* statSagas() {
  yield takeEvery(GET_STATS_REQUEST, statsSaga);
  yield takeEvery(GET_COMPANY_COUNT_REQUEST, companyCountSaga);
}
