export const GET_STATS_REQUEST = "GET_STATS_REQUEST";
export const GET_STATS_REQUEST__SUCCESS = "GET_STATS_REQUEST__SUCCESS";
export const GET_STATS_REQUEST__FAILURE = "GET_STATS_REQUEST__FAILURE";
export const GET_COMPANY_COUNT_REQUEST = "GET_COMPANY_COUNT_REQUEST";
export const GET_COMPANY_COUNT_REQUEST__SUCCESS = "GET_COMPANY_COUNT_REQUEST__SUCCESS";

export const getStats = (insuranceType, from = null) => ({type: GET_STATS_REQUEST, payload: {insuranceType, from}})
export const getCompanyCount = () => ({type: GET_COMPANY_COUNT_REQUEST })

const initialState = {medical: {}, dental: {}, isLoading: false, updated_at: null}
export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case GET_STATS_REQUEST:
      return {
        ...previousState,
        isLoading: true
      }
    case GET_STATS_REQUEST__SUCCESS:
      return {
        ...previousState,
        [payload.insuranceType]: payload.data,
        isLoading: false
      }
      break;
    case GET_STATS_REQUEST__FAILURE:
      return {
        ...previousState,
        [payload]: {},
        isLoading: false
      }
    case GET_COMPANY_COUNT_REQUEST__SUCCESS:
      return {
        ...previousState,
        [payload.insuranceType]: {
          ...previousState[payload.insuranceType],
          companies: payload.companies,
          plans: payload.plans,
          members: payload.members
        }
      }
      break;
    case "LOGIN_REQUEST__SUCCESS":
      return payload.data
      break;
    case "LOGOUT":
      return initialState;
    default:
      return previousState;
  }
}
