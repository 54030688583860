import React from 'react';

const Profile = ({className}) =>
<svg width="7em" height="7em" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
<path fill="#D4DCE9" d="M9.8,50.2l5.6-2.7c0,0,3.9-1.6,4.7-3.8c0.6-1.6,0.8-4.1,0.9-5.8c-1.5-1.5-2.5-3.5-2.6-5.7
	c-0.6-0.1-1.1-0.6-1.5-1.6c-0.5-1.5-1-3.8-0.6-4.7s0.8-1,1.6-1.1c-1.1-4-1.7-8.3-0.4-11.5c1.1-2.6,4.8-4.8,8.8-4.8h0.8
	c3.1,0,5.7,1,6.8,2.5c0.2,0,0.4-0.1,0.6-0.1h0.3c1.6,0,3,1.7,3.4,3.6c0.6,2.8,0.1,7-0.4,10.4c0.7,0.1,1.1,0.2,1.5,1.1
	s-0.1,3.2-0.6,4.7c-0.4,0.9-0.9,1.4-1.4,1.5c-0.1,2.4-1.2,4.5-2.8,5.9c0.1,1.7,0.3,4.1,0.8,5.6c0.8,2.2,4.7,3.8,4.7,3.8l5.6,2.7
	c5.5-4.9,9-12,9-20c0-14.8-12-26.7-26.7-26.7c-14.8,0-26.7,12-26.7,26.7C0.8,38.2,4.3,45.4,9.8,50.2z"/>
</svg>


export default Profile;
