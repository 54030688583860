export const SET_ERRORS = "SET_ERRORS";
export const RESET_FORM_STATE = "RESET_FORM_STATE";
export const UPDATE_FORM_VALUE = "UPDATE_FORM_VALUE";

export const setFormErrors = ({error, inputErrors}) => ({ type: SET_ERRORS, payload: { error, inputErrors } })
export const resetFormState  = () => ({type: RESET_FORM_STATE });
export const updateFormValue = (name, value) => ({type: UPDATE_FORM_VALUE, payload: {name, value}});

const initialState = { error: null, inputErrors: {}, values: {} };
export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case SET_ERRORS:
      return {
        ...previousState,
        error: payload.error,
        inputErrors: payload.inputErrors ||  {}
      }
    case UPDATE_FORM_VALUE:
      return {
        ...previousState,
        values: {
          ...previousState.values,
          [payload.name]: payload.value
        },
        inputErrors: {
          ...previousState.inputErrors,
          [payload.name]: false
        }
      }
    case RESET_FORM_STATE:
      return initialState;
    default:
      return previousState;
  }
}
