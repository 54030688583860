function getCashFormat(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    if(!amount || amount === "0.0"){
      return null
    }
    let i = parseInt(amount = Math.abs(Number(amount) || 0)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return "$"+(j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
  } catch (e) {
    console.log(e)
  }
}

function getPercentFormat(value){
  if(!value || value === "0.0"){
    return null
  }

  if(value > 1){
    return value > 100 ? 100 : Math.round(value)
  }
  return Math.round(value*100)
}

function getPercentFormatString(value){
  if(!value || value === "0.0"){
    return null
  }

  return getPercentFormat(value)+"%"
}

function getMMYY(date){
  let dateObj = new Date(date);
  let mm = dateObj.getMonth() + 1;

  let yy = dateObj.getFullYear().toString().substr(-2);
  if (mm < 10) {
    mm = '0' + mm;
  }
  return mm+'/'+yy;
}

function mapCommonStats(payload){
  let industryHeader = payload.our_values.industry_display[0][0];
  let industrySubheader = `${payload.our_values.industry_display[0][1]} - ${payload.our_values.industry_display[0][2]}`
  if(payload.our_values.industry_display.length > 0){
    for (var i = 1; i < payload.our_values.industry_display.length; i++) {
      industryHeader = industryHeader+", "+payload.our_values.industry_display[i][0];
      industrySubheader = industrySubheader+ `, ${payload.our_values.industry_display[i][1]} - ${payload.our_values.industry_display[i][2]}`;
    }
  }
  let regions = payload.our_values.geo_area[0] == "All" ? ["All Regions"] : payload.our_values.geo_area
  let regionCount = regions.length;
  let regionString = regions[0];
  if(regionCount > 1){
    for (var i = 1; i < regionCount; i++) {
      regionString = regionString+", "+regions[i]
    }
  }
  let updated = getMMYY(payload.our_values.updated_at);
  return {
    companies: payload.our_values.numcompanies ? payload.our_values.numcompanies.toLocaleString() : null,
    members: payload.our_values.nummembers ? payload.our_values.nummembers.toLocaleString() : null,
    plans: payload.our_values.numplans ? payload.our_values.numplans.toLocaleString() : null,
    hsa: payload.our_values.hsa ? Math.round(payload.our_values.hsa)+"%" : null,
    fi: payload.our_values.fi ? Math.round(payload.our_values.fi)+"%" : null,
    aso: payload.our_values.aso ? Math.round(payload.our_values.aso)+"%" : null,
    geo_area: payload.our_values.geo_area,
    states: payload.our_values.states,
    isLoading: false,
    user_query_id: payload.your_values.user_query_id,
    hsa_data: payload.hsa_data,
    industryHeader,
    industrySubheader,
    regionString,
    updated
  }
}

export function mapMedicalStatsFromResponse(payload, client){
  return {
    our_values: {
      family_deductible_in: { average: payload.our_values.famindedamt, median: payload.our_values.famindedamtmedian, popular: payload.our_values.famindedamtmode, percent: getPercentFormatString(payload.our_values.famindedamtmodepct) },
      family_pocket_in: { average: payload.our_values.faminoopmamt, median: payload.our_values.faminoopmamtmedian, popular: payload.our_values.faminoopmamtmode, percent: getPercentFormatString(payload.our_values.faminoopmamtmodepct) },
      family_coinsurance_in: { average: getPercentFormat(payload.our_values.inhospcoinspct), median: getPercentFormat(payload.our_values.inhospcoinspctmedian), popular: getPercentFormat(payload.our_values.inhospcoinspctmode), percent: getPercentFormatString(payload.our_values.inhospcoinspctmodepct) },
      family_deductible_out: { average: payload.our_values.famoutdedamt, median: payload.our_values.famoutdedamtmedian, popular: payload.our_values.famoutdedamtmode, percent: getPercentFormatString(payload.our_values.famoutdedamtmodepct) },
      family_pocket_out: { average: payload.our_values.famoutoopmamt, median: payload.our_values.famoutoopmamtmedian, popular: payload.our_values.famoutoopmamtmode, percent: getPercentFormatString(payload.our_values.famoutoopmamtmodepct) },
      family_coinsurance_out: { average: getPercentFormat(payload.our_values.outhospcoinspct), median: getPercentFormat(payload.our_values.outhospcoinspctmedian), popular: getPercentFormat(payload.our_values.outhospcoinspctmode), percent: getPercentFormatString(payload.our_values.outhospcoinspctmodepct) },
      individual_deductible_in: { average: payload.our_values.indivindedamt, median: payload.our_values.indivindedamtmedian, popular: payload.our_values.indivindedamtmode, percent: getPercentFormatString(payload.our_values.indivindedamtmodepct) },
      individual_pocket_in: { average: payload.our_values.indivinoopmamt, median: payload.our_values.indivinoopmamtmedian, popular: payload.our_values.indivinoopmamtmode, percent: getPercentFormatString(payload.our_values.indivinoopmamtmodepct) },
      individual_coinsurance_in: { average: getPercentFormat(payload.our_values.inhospcoinspct), median: getPercentFormat(payload.our_values.inhospcoinspctmedian), popular: getPercentFormat(payload.our_values.inhospcoinspctmode), percent: getPercentFormatString(payload.our_values.inhospcoinspctmodepct) },
      individual_deductible_out: { average: payload.our_values.indivoutdedamt, median: payload.our_values.indivoutdedamtmedian, popular: payload.our_values.indivoutdedamtmode, percent: getPercentFormatString(payload.our_values.indivoutdedamtmodepct) },
      individual_pocket_out: { average: payload.our_values.indivoutoopmamt, median: payload.our_values.indivoutoopmamtmedian, popular: payload.our_values.indivoutoopmamtmode, percent: getPercentFormatString(payload.our_values.indivoutoopmamtmodepct) },
      individual_coinsurance_out: { average: getPercentFormat(payload.our_values.outhospcoinspct), median: getPercentFormat(payload.our_values.outhospcoinspctmedian), popular: getPercentFormat(payload.our_values.outhospcoinspctmode), percent: getPercentFormatString(payload.our_values.outhospcoinspctmodepct) },
      office: {average: getCashFormat(payload.our_values.physincopayamt), median: getCashFormat(payload.our_values.physincopayamtmedian), popular: getCashFormat(payload.our_values.physincopayamtmode), percent: getPercentFormatString(payload.our_values.physincopayamtmodepct) },
      specialist: {average: getCashFormat(payload.our_values.specincopayamt), median: getCashFormat(payload.our_values.specincopayamtmedian), popular: getCashFormat(payload.our_values.specincopayamtmode), percent: getPercentFormatString(payload.our_values.specincopayamtmodepct) },
      urgentcare: {average: getCashFormat(payload.our_values.urgentcarecopayamt), median: getCashFormat(payload.our_values.urgentcarecopayamtmedian), popular: getCashFormat(payload.our_values.urgentcarecopayamtmode), percent: getPercentFormatString(payload.our_values.urgentcarecopayamtmodepct) },
      er: {average: getCashFormat(payload.our_values.ercopayamt), median: getCashFormat(payload.our_values.ercopayamtmedian), popular: getCashFormat(payload.our_values.ercopayamtmode), percent: getPercentFormatString(payload.our_values.ercopayamtmodepct) },
      tier1amt: {average: getCashFormat(payload.our_values.copaytier1), median: getCashFormat(payload.our_values.copaytier1median), popular: getCashFormat(payload.our_values.copaytier1mode), percent: getPercentFormatString(payload.our_values.copaytier1modepct)},
      tier2amt: {average: getCashFormat(payload.our_values.copaytier2), median: getCashFormat(payload.our_values.copaytier2median), popular: getCashFormat(payload.our_values.copaytier2mode), percent: getPercentFormatString(payload.our_values.copaytier2modepct)},
      tier3amt: {average: getCashFormat(payload.our_values.copaytier3), median: getCashFormat(payload.our_values.copaytier3median), popular: getCashFormat(payload.our_values.copaytier3mode), percent: getPercentFormatString(payload.our_values.copaytier3modepct)},
      tier4amt: {average: getCashFormat(payload.our_values.copaytier4), median: getCashFormat(payload.our_values.copaytier4median), popular: getCashFormat(payload.our_values.copaytier4mode), percent: getPercentFormatString(payload.our_values.copaytier4modepct)},
      tier1pct: {average: getPercentFormatString(payload.our_values.coinstier1), median: getPercentFormatString(payload.our_values.coinstier1median), popular: getPercentFormatString(payload.our_values.coinstier1mode), percent: getPercentFormatString(payload.our_values.coinstier1modepct)},
      tier2pct: {average: getPercentFormatString(payload.our_values.coinstier2), median: getPercentFormatString(payload.our_values.coinstier2median), popular: getPercentFormatString(payload.our_values.coinstier2mode), percent: getPercentFormatString(payload.our_values.coinstier2modepct)},
      tier3pct: {average: getPercentFormatString(payload.our_values.coinstier3), median: getPercentFormatString(payload.our_values.coinstier3median), popular: getPercentFormatString(payload.our_values.coinstier3mode), percent: getPercentFormatString(payload.our_values.coinstier3modepct)},
      tier4pct: {average: getPercentFormatString(payload.our_values.coinstier4), median: getPercentFormatString(payload.our_values.coinstier4median), popular: getPercentFormatString(payload.our_values.coinstier4mode), percent: getPercentFormatString(payload.our_values.coinstier4modepct)},
      percent_tier4: getPercentFormatString(payload.our_values.percent_tier4),
      products: payload.our_values.products,
      product: payload.our_values.product,
      numcompaniesrx: payload.our_values.numcompaniesrx
    },
    your_values: {
      family_deductible_in: payload.your_values.famindedamt,
      family_pocket_in: payload.your_values.faminoopmamt,
      family_coinsurance_in: getPercentFormat(payload.your_values.inhospcoinspct),
      family_deductible_out: payload.your_values.famoutdedamt,
      family_pocket_out: payload.your_values.famoutoopmamt,
      family_coinsurance_out: getPercentFormat(payload.your_values.outhospcoinspct),
      individual_deductible_in: payload.your_values.indivindedamt,
      individual_pocket_in: payload.your_values.indivinoopmamt,
      individual_coinsurance_in: getPercentFormat(payload.your_values.inhospcoinspct),
      individual_deductible_out: payload.your_values.indivoutdedamt,
      individual_pocket_out: payload.your_values.indivoutoopmamt,
      individual_coinsurance_out: getPercentFormat(payload.your_values.outhospcoinspct),
      office: getCashFormat(payload.your_values.physincopayamt),
      specialist: getCashFormat(payload.your_values.specincopayamt),
      urgentcare: getCashFormat(payload.your_values.urgentcarecopayamt),
      er: getCashFormat(payload.your_values.ercopayamt),
      tier1amt: getCashFormat(payload.your_values.copaytier1),
      tier2amt: getCashFormat(payload.your_values.copaytier2),
      tier3amt: getCashFormat(payload.your_values.copaytier3),
      tier4amt: getCashFormat(payload.your_values.copaytier4),
      tier1pct: getPercentFormat(payload.your_values.coinstier1),
      tier2pct: getPercentFormat(payload.your_values.coinstier2),
      tier3pct: getPercentFormat(payload.your_values.coinstier3),
      tier4pct: getPercentFormat(payload.your_values.coinstier4)
    },
    client,
    top_plans: (payload.our_values.top_plans && payload.our_values.top_plans.length > 0) ? payload.our_values.top_plans : null,
    ...mapCommonStats(payload)
  }
}


export function mapDentalStatsFromResponse(payload, client){
  return {
    our_values: {
      deductible_family_in: {average: payload.our_values.fam_in_deduct, median: payload.our_values.fam_in_deduct_median, popular: payload.our_values.fam_in_deduct_mode },
      deductible_family_out: {average: payload.our_values.fam_out_deduct, median: payload.our_values.fam_out_deduct_median, popular: payload.our_values.fam_out_deduct_mode },
      deductible_individual_in: {average: payload.our_values.indiv_in_deduct, median: payload.our_values.indiv_in_deduct_median, popular: payload.our_values.indiv_in_deduct_mode },
      deductible_individual_out: {average: payload.our_values.indiv_out_deduct, median: payload.our_values.indiv_out_deduct_median, popular: payload.our_values.indiv_out_deduct_mode },
      max_max_in: {average: payload.our_values.in_max, median: payload.our_values.in_max_median, popular: payload.our_values.in_max_mode },
      max_max_out: {average: payload.our_values.out_max, median: payload.our_values.out_max_median, popular: payload.our_values.out_max_mode },
      services_preventive_in: {average: getPercentFormat(payload.our_values.in_basic), median: getPercentFormat(payload.our_values.in_basic_median), popular: getPercentFormat(payload.our_values.in_basic_mode) },
      services_preventive_out: {average: getPercentFormat(payload.our_values.out_basic), median: getPercentFormat(payload.our_values.out_basic_median), popular: getPercentFormat(payload.our_values.out_basic_mode) },
      services_dentures_in: {average: getPercentFormat(payload.our_values.in_removable), median: getPercentFormat(payload.our_values.in_removable_median), popular: getPercentFormat(payload.our_values.in_removable_mode) },
      services_dentures_out: {average: getPercentFormat(payload.our_values.out_removable), median: getPercentFormat(payload.our_values.out_removable_median), popular: getPercentFormat(payload.our_values.out_removable_mode) },
      services_crowns_in: {average: getPercentFormat(payload.our_values.in_inlays), median: getPercentFormat(payload.our_values.in_inlays_median), popular: getPercentFormat(payload.our_values.in_inlays_mode) },
      services_crowns_out: {average: getPercentFormat(payload.our_values.out_inlays), median: getPercentFormat(payload.our_values.out_inlays_median), popular: getPercentFormat(payload.our_values.out_inlays_mode) },
      services_restorative_in: {average: getPercentFormat(payload.our_values.in_minor_rest), median: getPercentFormat(payload.our_values.in_minor_rest_median), popular: getPercentFormat(payload.our_values.in_minor_rest_mode) },
      services_restorative_out: {average: getPercentFormat(payload.our_values.out_minor_rest), median: getPercentFormat(payload.our_values.out_minor_rest_median), popular: getPercentFormat(payload.our_values.out_minor_rest_mode) },
      services_extractions_in: {average: getPercentFormat(payload.our_values.in_simple_ext), median: getPercentFormat(payload.our_values.in_simple_ext_median), popular: getPercentFormat(payload.our_values.in_simple_ext_mode) },
      services_extractions_out: {average: getPercentFormat(payload.our_values.out_simple_ext), median: getPercentFormat(payload.our_values.out_simple_ext_median), popular: getPercentFormat(payload.our_values.out_simple_ext_mode) },
      services_implants_in: {average: getPercentFormat(payload.our_values.in_implants), median: getPercentFormat(payload.our_values.in_implants_median), popular: getPercentFormat(payload.our_values.in_implants_mode) },
      services_implants_out: {average: getPercentFormat(payload.our_values.out_implants), median: getPercentFormat(payload.our_values.out_implants_median), popular: getPercentFormat(payload.our_values.out_implants_mode) },
      ortho_max_in: {average: payload.our_values.in_ortho_max, median: payload.our_values.in_ortho_max_median, popular: payload.our_values.in_ortho_max_mode },
      ortho_max_out: {average: payload.our_values.out_ortho_max, median: payload.our_values.out_ortho_max_median, popular: payload.our_values.out_ortho_max_mode },
      ortho_care_in: {average: getPercentFormat(payload.our_values.in_ortho), median: getPercentFormat(payload.our_values.in_ortho_median), popular: getPercentFormat(payload.our_values.in_ortho_mode) },
      ortho_care_out: {average: getPercentFormat(payload.our_values.out_ortho), median: getPercentFormat(payload.our_values.out_ortho_median), popular: getPercentFormat(payload.our_values.out_ortho_mode) },
      specialties_perio_in: {average: getPercentFormat(payload.our_values.in_perio), median: getPercentFormat(payload.our_values.in_perio_median), popular: getPercentFormat(payload.our_values.in_perio_mode) },
      specialties_perio_out: {average: getPercentFormat(payload.our_values.out_perio), median: getPercentFormat(payload.our_values.out_perio_median), popular: getPercentFormat(payload.our_values.out_perio_mode) },
      specialties_periononsurg_in: {average: getPercentFormat(payload.our_values.in_perio_non), median: getPercentFormat(payload.our_values.in_perio_non_median), popular: getPercentFormat(payload.our_values.in_perio_non_mode) },
      specialties_periononsurg_out: {average: getPercentFormat(payload.our_values.out_perio_non), median: getPercentFormat(payload.our_values.out_perio_non_median), popular: getPercentFormat(payload.our_values.out_perio_non_mode) },
      specialties_periosurg_in: {average: getPercentFormat(payload.our_values.in_perio_surg), median: getPercentFormat(payload.our_values.in_perio_surg_median), popular: getPercentFormat(payload.our_values.in_perio_surg_mode) },
      specialties_periosurg_out: {average: getPercentFormat(payload.our_values.out_perio_surg), median: getPercentFormat(payload.our_values.out_perio_surg_median), popular: getPercentFormat(payload.our_values.out_perio_surg_mode) },
      specialties_endo_in: {average: getPercentFormat(payload.our_values.in_endo), median: getPercentFormat(payload.our_values.in_endo_median), popular: getPercentFormat(payload.our_values.in_endo_mode) },
      specialties_endo_out: {average: getPercentFormat(payload.our_values.out_endo), median: getPercentFormat(payload.our_values.out_endo_median), popular: getPercentFormat(payload.our_values.out_endo_mode) },
      specialties_oral_in: {average: getPercentFormat(payload.our_values.in_oral), median: getPercentFormat(payload.our_values.in_oral_median), popular: getPercentFormat(payload.our_values.in_oral_mode) },
      specialties_oral_out: {average: getPercentFormat(payload.our_values.out_oral), median: getPercentFormat(payload.our_values.out_oral_median), popular: getPercentFormat(payload.our_values.out_oral_mode) },
      specialties_pros_in: {average: getPercentFormat(payload.our_values.in_fixed_pros), median: getPercentFormat(payload.our_values.in_fixed_pros_median), popular: getPercentFormat(payload.our_values.in_fixed_pros_mode) },
      specialties_pros_out: {average: getPercentFormat(payload.our_values.out_fixed_pros), median: getPercentFormat(payload.our_values.out_fixed_pros_median), popular: getPercentFormat(payload.our_values.out_fixed_pros_mode) },
    },
    your_values: {
      deductible_family_in: payload.your_values.fam_in_deduct,
      deductible_family_out: payload.your_values.fam_out_deduct,
      deductible_individual_in: payload.your_values.indiv_in_deduct,
      deductible_individual_out: payload.your_values.indiv_out_deduct,
      max_max_in: payload.your_values.in_max,
      max_max_out: payload.your_values.out_max,
      services_preventive_in: getPercentFormat(payload.your_values.in_basic),
      services_preventive_out: getPercentFormat(payload.your_values.out_basic),
      services_dentures_in: getPercentFormat(payload.your_values.in_removable),
      services_dentures_out: getPercentFormat(payload.your_values.out_removable),
      services_crowns_in: getPercentFormat(payload.your_values.in_inlays),
      services_crowns_out: getPercentFormat(payload.your_values.out_inlays),
      services_restorative_in: getPercentFormat(payload.your_values.in_minor_rest),
      services_restorative_out: getPercentFormat(payload.your_values.out_minor_rest),
      services_extractions_in: getPercentFormat(payload.your_values.in_simple_ext),
      services_extractions_out: getPercentFormat(payload.your_values.out_simple_ext),
      services_implants_in: getPercentFormat(payload.your_values.in_implants),
      services_implants_out: getPercentFormat(payload.your_values.out_implants),
      ortho_max_in: payload.your_values.in_ortho_max,
      ortho_max_out: payload.your_values.out_ortho_max,
      ortho_care_in: getPercentFormat(payload.your_values.in_ortho),
      ortho_care_out: getPercentFormat(payload.your_values.out_ortho),
      specialties_perio_in: getPercentFormat(payload.your_values.in_perio),
      specialties_perio_out: getPercentFormat(payload.your_values.out_perio),
      specialties_periononsurg_in: getPercentFormat(payload.your_values.in_perio_non),
      specialties_periononsurg_out: getPercentFormat(payload.your_values.out_perio_non),
      specialties_periosurg_in: getPercentFormat(payload.your_values.in_perio_surg),
      specialties_periosurg_out: getPercentFormat(payload.your_values.out_perio_surg),
      specialties_endo_in: getPercentFormat(payload.your_values.in_endo),
      specialties_endo_out: getPercentFormat(payload.your_values.out_endo),
      specialties_oral_in: getPercentFormat(payload.your_values.in_oral),
      specialties_oral_out: getPercentFormat(payload.your_values.out_oral),
      specialties_pros_in: getPercentFormat(payload.your_values.in_fixed_pros),
      specialties_pros_out: getPercentFormat(payload.your_values.out_fixed_pros)
    },
    client,
    ...mapCommonStats(payload)
  }
}

const addCommas = (value) => value ? Math.round(value).toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null


function mapQuoteToState(quote){
  return {
    ercopayamt: addCommas(quote.ercopayamt),
    famindedamt: addCommas(quote.famindedamt),
    faminoopmamt: addCommas(quote.faminoopmamt),
    famoutdedamt: addCommas(quote.famoutdedamt),
    famoutoopmamt: addCommas(quote.famoutoopmamt),
    indivindedamt: addCommas(quote.indivindedamt),
    indivinoopmamt: addCommas(quote.indivinoopmamt),
    indivoutdedamt: addCommas(quote.indivoutdedamt),
    indivoutoopmamt: addCommas(quote.indivoutoopmamt),
    inhospcoinspct: addCommas(quote.inhospcoinspct),
    outhospcoinspct: addCommas(quote.outhospcoinspct),
    physincopayamt: addCommas(quote.physincopayamt),
    productname: addCommas(quote.productname),
    specincopayamt: addCommas(quote.specincopayamt),
    urgentcarecopayamt: addCommas(quote.urgentcarecopayamt)
  }
}

function mapRxQuoteToState(quote){
  return {
    coinstier1: addCommas(quote.coinstier1),
    coinstier2: addCommas(quote.coinstier2),
    coinstier3: addCommas(quote.coinstier3),
    coinstier4: addCommas(quote.coinstier4),
    copaytier1: addCommas(quote.copaytier1),
    copaytier2: addCommas(quote.copaytier2),
    copaytier3: addCommas(quote.copaytier3),
    copaytier4: addCommas(quote.copaytier4)
  }
}

function mapDentalQuoteToState(quote){
  return {
    fam_in_deduct: addCommas(quote.fam_in_deduct),
    fam_in_office: addCommas(quote.fam_in_office),
    fam_out_deduct: addCommas(quote.fam_out_deduct),
    fam_out_office: addCommas(quote.fam_out_office),
    in_basic: addCommas(quote.in_basic),
    in_endo: addCommas(quote.in_endo),
    in_fixed_pros: addCommas(quote.in_fixed_pros),
    in_implants: addCommas(quote.in_implants),
    in_inlays: addCommas(quote.in_inlays),
    in_major: addCommas(quote.in_major),
    in_max: addCommas(quote.in_max),
    in_minor_rest: addCommas(quote.in_minor_rest),
    in_oral: addCommas(quote.in_oral),
    in_ortho: addCommas(quote.in_ortho),
    in_ortho_max: addCommas(quote.in_ortho_max),
    in_perio: addCommas(quote.in_perio),
    in_perio_non: addCommas(quote.in_perio_non),
    in_perio_surg: addCommas(quote.in_perio_surg),
    in_removable: addCommas(quote.in_removable),
    in_simple_ext: addCommas(quote.in_simple_ext),
    indiv_in_deduct: addCommas(quote.indiv_in_deduct),
    indiv_in_office: addCommas(quote.indiv_in_office),
    indiv_out_deduct: addCommas(quote.indiv_out_deduct),
    indiv_out_office: addCommas(quote.indiv_out_office),
    out_basic: addCommas(quote.out_basic),
    out_endo: addCommas(quote.out_endo),
    out_fixed_pros: addCommas(quote.out_fixed_pros),
    out_implants: addCommas(quote.out_implants),
    out_inlays: addCommas(quote.out_inlays),
    out_major: addCommas(quote.out_major),
    out_max: addCommas(quote.out_max),
    out_minor_rest: addCommas(quote.out_minor_rest),
    out_oral: addCommas(quote.out_oral),
    out_ortho: addCommas(quote.out_ortho),
    out_ortho_max: addCommas(quote.out_ortho_max),
    out_perio: addCommas(quote.out_perio),
    out_perio_non: addCommas(quote.out_perio_non),
    out_perio_surg: addCommas(quote.out_perio_surg),
    out_removable: addCommas(quote.out_removable),
    out_simple_ext: addCommas(quote.out_simple_ext)
  }
}

export function mapCompanyToState(company){
  if(company.company_quote_rx){
    company.company_quote_rx_id = company.company_quote_rx.id
    company.company_quote_rx = mapRxQuoteToState(company.company_quote_rx)
  }
  if(company.company_quote){
    company.company_quote_id = company.company_quote.id
    company.company_quote = mapQuoteToState(company.company_quote)
  }
  if(company.company_quote_dental){
    company.company_quote_dental_id = company.company_quote_dental.id
    company.company_quote_dental = mapDentalQuoteToState(company.company_quote_dental)
  }
  return company;
}

export function haveValues(object){
  for (var field in object) {
    if (object.hasOwnProperty(field)) {
      if(typeof object[field] === "string" && object[field].length > 0) return true
    }
  }
  return false
}
