import React from 'react';
import { NavLink } from 'react-router-dom';
import ModalTrigger from '../../containers/ModalTrigger';
import GearIcon from '../SVGComponents/Gear';
import styles from './index.module.css';

const UserMenu = ({name, isOpen, toggleOpen, menuRef, admin}) =>
  <div className={styles.wrapper}>
    <GearIcon className={styles.toggle} onClick={toggleOpen} />
    {isOpen &&
      <div ref={menuRef} className={styles.menu}>
        <div className={styles.name}>{name}</div>
        <div className={styles.links}>
          <NavLink to="/profile" className={styles.link} activeClassName={styles.activeLink} onClick={toggleOpen}>Profile</NavLink>
          <NavLink to="/tutorials" className={styles.link} activeClassName={styles.activeLink} onClick={toggleOpen}>Tutorials</NavLink>
          <ModalTrigger modal="tips" onClick={toggleOpen} className={styles.link}>
            Tips
          </ModalTrigger>
          <NavLink to="/contact" className={styles.link} activeClassName={styles.activeLink} onClick={toggleOpen}>Contact Us</NavLink>
          {admin && <a href="/admin/users" className={styles.link}>Admin</a>}
        </div>
        <ModalTrigger modal="signout" onClick={toggleOpen} className={styles.logout}>
          Sign Out
        </ModalTrigger>
      </div>
    }
  </div>

export default UserMenu
