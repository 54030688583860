import { takeEvery, put, call } from 'redux-saga/effects';
import API from '../utils/API';
import {
  OPTIONS_REQUEST,
  OPTIONS_REQUEST__SUCCESS,
  OPTIONS_REQUEST__FAILURE
} from '../store/options';

function mapBenchmarkOptions(options){
  let regions = options.geo.regions.map(region => ({value: region.id, label: region.name, type: "region_id"}));
  let group = [{value: {max_value: options.groupsizemax, min_value: 1}, label: "All"}, {value: {max_value: 99, min_value: 1}, label: "Min. 1 - Max. 99"}, {value: {max_value: 199, min_value: 100}, label: "Min. 100 - Max. 199"}, {value: {max_value: 499, min_value: 200}, label: "Min. 200 - Max. 499"}, {value: {max_value: null, min_value: 500}, label: "Min. 500"}]
  let industry = [];
  for (let i = 0; i < options.industries.major_industries.length; i++) {
    industry.push({value: options.industries.major_industries[i].id, label: options.industries.major_industries[i].title, subcategory: "major_industry"});
  }
  return {
    regions,
    group,
    industry
  }
}

function* optionsSaga(action){
  try {
    let response = yield call(API.get, "api/options.json");
    if(!response.errors){
      let options = mapBenchmarkOptions(response.options)
      yield put({ type: OPTIONS_REQUEST__SUCCESS, payload: options});
    } else {
      console.log(response.mssg);
    }

  } catch (e) {
    yield put({ type: OPTIONS_REQUEST__FAILURE, payload: "Invalid" });
  }
}


export default function* optionsSagas() {
  yield takeEvery(OPTIONS_REQUEST, optionsSaga);
}
