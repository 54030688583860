import React from 'react';

const Gear = ({className, onClick}) =>
<svg width="2.3em" className={className} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.8 268.8">
<g>
	<g>
		<path fill="#7B8AA4" d="M267.9,119.5c-0.4-3.8-4.8-6.6-8.6-6.6c-12.3,0-23.2-7.2-27.8-18.4c-4.7-11.5-1.7-24.8,7.5-33.2
			c2.9-2.6,3.2-7.1,0.8-10.1c-6.3-8-13.5-15.2-21.3-21.5c-3.1-2.5-7.6-2.1-10.2,0.8c-8,8.9-22.4,12.2-33.5,7.5
			c-11.6-4.9-18.9-16.6-18.2-29.2c0.2-4-2.7-7.4-6.6-7.8c-10-1.2-20.2-1.2-30.2-0.1c-3.9,0.4-6.8,3.8-6.7,7.7
			c0.4,12.5-6.9,24-18.4,28.7c-11,4.5-25.3,1.2-33.3-7.6c-2.6-2.9-7.1-3.3-10.1-0.9c-8.1,6.3-15.4,13.6-21.7,21.5
			c-2.5,3.1-2.1,7.6,0.8,10.2c9.4,8.5,12.4,21.9,7.5,33.5c-4.6,11-16.1,18.2-29.2,18.2c-4.3-0.1-7.3,2.7-7.8,6.6
			c-1.2,10.1-1.2,20.4-0.1,30.6c0.4,3.8,5,6.6,8.8,6.6c11.7-0.3,22.9,6.9,27.6,18.4c4.7,11.5,1.7,24.8-7.5,33.2
			c-2.9,2.6-3.2,7.1-0.8,10.1c6.2,8,13.4,15.2,21.3,21.5c3.1,2.5,7.6,2.1,10.2-0.8c8-8.9,22.4-12.2,33.5-7.5
			c11.6,4.9,18.9,16.6,18.2,29.2c-0.2,4,2.7,7.4,6.6,7.9c5.1,0.6,10.3,0.9,15.5,0.9c4.9,0,9.8-0.3,14.8-0.8c3.9-0.4,6.8-3.8,6.7-7.7
			c-0.5-12.5,6.9-24,18.4-28.7c11.1-4.5,25.3-1.2,33.3,7.6c2.7,2.9,7,3.2,10.1,0.8c8-6.3,15.3-13.5,21.7-21.5
			c2.5-3.1,2.1-7.6-0.8-10.2c-9.4-8.5-12.4-21.9-7.5-33.5c4.6-10.9,15.6-18.2,27.5-18.2l1.7,0c3.9,0.3,7.4-2.7,7.9-6.6
			C269,139.9,269.1,129.6,267.9,119.5z M134.6,179.5c-24.7,0-44.8-20.1-44.8-44.8c0-24.7,20.1-44.8,44.8-44.8
			c24.7,0,44.8,20.1,44.8,44.8C179.4,159.4,159.3,179.5,134.6,179.5z"/>
	</g>
</g>
</svg>

export default Gear;
