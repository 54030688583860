import { LOGIN_REQUEST__SUCCESS } from './user';
export const SAVE_PDF = "SAVE_PDF";
export const SAVE_PDF_REQUEST = "SAVE_PDF_REQUEST";
export const SAVE_PDF_REQUEST__SUCCESS = "SAVE_PDF_REQUEST__SUCCESS";
export const SAVE_PDF_REQUEST__FAILURE = "SAVE_PDF_REQUEST__FAILURE";
export const REMOVE_PDF_REQUEST = "REMOVE_PDF_REQUEST";
export const REMOVE_PDF_REQUEST__SUCCESS = "REMOVE_PDF_REQUEST__SUCCESS";
export const REMOVE_PDF_REQUEST__FAILURE = "REMOVE_PDF_REQUEST__FAILURE";

export const startExport = () => ({type: SAVE_PDF})
export const savePdf = (blob) => ({type: SAVE_PDF_REQUEST, payload: blob})
export const removePdf = (pdf) => ({type: REMOVE_PDF_REQUEST, payload: pdf})

const initialState = { list: [], isLoading: false, current: null }
export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case SAVE_PDF:
      return {
        ...previousState,
        isLoading: true,
        current: null
      }
    case SAVE_PDF_REQUEST__SUCCESS:
      return {
        list: payload.list,
        isLoading: false,
        current: payload.current
      }
    case SAVE_PDF_REQUEST__FAILURE:
      return {
        ...initialState,
        isLoading: false
      }
    case REMOVE_PDF_REQUEST__SUCCESS:
      return {
        ...previousState,
        list: payload
      }
    case LOGIN_REQUEST__SUCCESS:
      return {
        ...initialState,
        list: payload.pdf
      }
      break;
    case "LOGOUT":
      return initialState;
    default:
      return previousState;
  }
}
