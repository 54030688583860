import { takeEvery, put, call, select, take, race, debounce } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import API from '../utils/API';
import {makeClientMap} from '../utils'
import { mapCompanyToState, haveValues } from '../utils/data';
import {
  SELECT_CLIENT,
  GET_CLIENT_COMPARISON_PROFILE__SUCCESS,
  SAVE_COMPARISON_VALUES
} from '../store/comparison';
import {
  planError,
  GET_MORE_ADMIN_PLANS_REQUEST,
  GET_MORE_ADMIN_PLANS_REQUEST__SUCCESS,
  FILTERED_ADMIN_PLANS_REQUEST__SUCCESS
} from '../store/adminPlans';
import {
  CONFIRM_ACTION,
  CLOSE_MODAL,
  openModal
} from '../store/display';
import {
  addError
} from '../store/errors';
import {
  addClient,
  addClientToList,
  removeClientFromList,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_REQUEST__SUCCESS,
  DELETE_COMPANY_REQUEST__FAILURE,
  SET_CLIENT_FILTER
} from '../store/clients';
import {
  CLICK_ADMIN_FILTER_REQUEST_BUTTON
} from '../store/adminPlans';
import {
  ErrorHandler
} from './user';

const getUserState = ({user}) => user;
const getComparisonState = ({comparison}) => comparison;
const checkIfInsuranceIsMedical = ({display}) => display.insurance === "medical" ? true : false;

function* comparisonProfileRequestSaga({payload}){
  try {
    let company = yield call(API.get, `companies/${payload.id}.json`);
    yield put({type: GET_CLIENT_COMPARISON_PROFILE__SUCCESS, payload: mapCompanyToState(company)})
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(addError("Unable to retrieve client comparison data."));
    }
  }
}
function checkIfUpdated(initialObj = {}, newObj = {}){
  let updates = {}
  for (var field in newObj) {
    if (newObj.hasOwnProperty(field)){
        updates[field] = typeof newObj[field] === 'string' ? newObj[field].replace(/,/g,'') : null
    }
  }
  return Object.keys(updates).length > 0 ? updates : false;
}

function removeCommas(obj){
  for (var field in obj) {
    if (obj.hasOwnProperty(field)){
      obj[field] = Number(obj[field].replace(/,/g,''))
    }
  }
  return obj;
}

function addClientToStorage(client, hasMedical, hasDental, newClient){
  let clientStorage = sessionStorage.getItem('clients');
  let clients = JSON.parse(clientStorage);
  if(hasMedical){
    clients.medical.added.push(client)
  }
  if(hasDental){
    clients.dental.added.push(client)
  }
  if(newClient){
    clients.added.push(client)
  }
  sessionStorage.setItem('clients', JSON.stringify(clients));
}

function removeClientFromStorage(company, medical, dental, added){
  let clientStorage = sessionStorage.getItem('clients');
  let clients = JSON.parse(clientStorage);
  if(medical){
    clients.medical.added = clients.medical.added.filter(client => client.id !== company.id)
  }
  if(dental){
    clients.dental.added = clients.dental.added.filter(client => client.id !== company.id)
  }
  if(added){
    clients.added = clients.added.filter(client => client.id !== company.id)
  }
  sessionStorage.setItem('clients', JSON.stringify(clients))
}

function* newCompanyDataSaga(){
  try {
    let {id, organization_id} = yield select(getUserState);
    let comparison = yield select(getComparisonState)
    let isMedical = yield select(checkIfInsuranceIsMedical);
    if(comparison.company){
      let updatedCompanyObj = {};
      if(isMedical){
        let updatedQuote = checkIfUpdated(comparison.company.company_quote, comparison.company_quote);
        let updatedRxQuote = checkIfUpdated(comparison.company.company_quote_rx, comparison.company_quote_rx);
        if(updatedQuote){
          updatedCompanyObj.company_quote_attributes = updatedQuote;
        }
        if(updatedRxQuote){
          updatedCompanyObj.company_quote_rx_attributes = updatedRxQuote;
        }
      } else {
        let updatedDentalQuote = checkIfUpdated(comparison.company.company_quote_dental, comparison.company_quote_dental);
        if(updatedDentalQuote){
          updatedCompanyObj.company_quote_dental_attributes = updatedDentalQuote;
        }
      }
      if((isMedical && (updatedCompanyObj.company_quote_attributes || updatedCompanyObj.company_quote_rx_attributes)) || (!isMedical && updatedCompanyObj.company_quote_dental_attributes)){
        let update = yield call(API.patch, `companies/${comparison.company.id}.json`, {company: updatedCompanyObj});
        yield put(push('/dashboard'));
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    } else if(comparison.input && comparison.input.length > 0) {
      let newCompanyObj = {
        name: comparison.input,
        user_id: id,
        organization_id: organization_id,
        public: true,
      }
      if(isMedical){
        let hasMedical = Object.keys(comparison.company_quote).length > 0 || Object.keys(comparison.company_quote_rx).length > 0;
        if(hasMedical){
          newCompanyObj.company_quote_attributes = removeCommas(comparison.company_quote)
          newCompanyObj.company_quote_rx_attributes = removeCommas(comparison.company_quote_rx)
        }
      } else {
        let hasDental = Object.keys(comparison.company_quote_dental).length > 0;
        if(hasDental){
          newCompanyObj.company_quote_dental_attributes = removeCommas(comparison.company_quote_dental)
        }
      }
      if((isMedical && (newCompanyObj.company_quote_attributes || newCompanyObj.company_quote_rx_attributes)) || (!isMedical && newCompanyObj.company_quote_dental_attributes)){
        let client = yield call(API.post, "companies.json", {company: newCompanyObj});
        yield put(addClient(client, isMedical, !isMedical));
        yield put(push('/dashboard'));
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        addClientToStorage(client, isMedical, !isMedical, true)
      }
    } else {
      yield put(addError("Select a company or enter a company name"))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(addError("There was an error handling your request."))
    }
  }
}

function* deleteCompanySaga(){
  try {
    yield put(openModal('confirmation'))
    const {confirm, cancel} = yield race({
      confirm: take(CONFIRM_ACTION),
      cancel: take(CLOSE_MODAL)
    })
    if(confirm){
      let { company } = yield select(getComparisonState)
      let isMedical = yield select(checkIfInsuranceIsMedical);
      let response = yield call(API.delete, `companies/${company.id}.json`);
      if(response.ok){
        yield put({type: DELETE_COMPANY_REQUEST__SUCCESS, payload: company.id})

        removeClientFromStorage(company, isMedical, !isMedical, true)
        yield put(push("/dashboard"));
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      } else {
        throw "Error"
      }
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(addError("Unable to process request"))
    }
  }
}


const getInsuranceState = ({display}) => display.insurance
const getQueryState = ({display, clients, clientRegionFilter, adminPlans}) => ({insuranceType: display.insurance, benchmarks: clientRegionFilter[display.insurance], adminPlans: adminPlans[display.insurance], filter: clients.filter});
function* getMoreAdminClientPlansSaga(){
  try {
    let {benchmarks,insuranceType,filter,adminPlans} = yield select(getQueryState);
    let params = {medical: insuranceType === "medical",limit: adminPlans.limit, offset: adminPlans.offset}
    if(benchmarks.regions.length > 0 && benchmarks.regions[0].type === "region_id"){
      params.region_ids = benchmarks.regions.map(region => region.value);
    } else if ((benchmarks.regions.length > 0 && benchmarks.regions[0].label !== "All" ) || benchmarks.markets.length > 0 ){
      if(benchmarks.regions.length > 0){
        params.state_ids = benchmarks.regions.map(state => state.value);
      } else {
        params.market_ids = benchmarks.markets.map(market => market.value);
      }
    }
    if(filter){
      params.name = filter;
    }
    let plans = yield call(API.post, 'api/get_plans', params);

    yield put({type: GET_MORE_ADMIN_PLANS_REQUEST__SUCCESS, payload: {
      [insuranceType]: {
        ...makeClientMap(plans.plans,adminPlans),
        count: plans.count,
        limit: adminPlans.limit,
        offset: adminPlans.offset+adminPlans.limit,
        hasCount: true,
        isLoading: false,
        loadingNext: false,
        error: false
      }
    }})
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      let insurance = yield select(getInsuranceState);
      yield put(planError(insurance))
    }
  }
}

function* setAdminClientPlansFilterSaga(){
  try {
    let {benchmarks,insuranceType,filter,adminPlans} = yield select(getQueryState);
    let params = {medical: insuranceType === "medical",limit: adminPlans.limit}
    if(benchmarks.regions.length > 0 && benchmarks.regions[0].type === "region_id"){
      params.region_ids = benchmarks.regions.map(region => region.value);
    } else if ((benchmarks.regions.length > 0 && benchmarks.regions[0].label !== "All" ) || benchmarks.markets.length > 0 ){
      if(benchmarks.regions.length > 0){
        params.state_ids = benchmarks.regions.map(state => state.value);
      } else {
        params.market_ids = benchmarks.markets.map(market => market.value);
      }
    }
    if(filter){
      params.name = filter;
    }
    let plans = yield call(API.post, 'api/get_plans', params);

    yield put({type: FILTERED_ADMIN_PLANS_REQUEST__SUCCESS, payload: {
      [insuranceType]: {
        ...makeClientMap(plans.plans),
        count: plans.count,
        limit: adminPlans.limit,
        offset: adminPlans.limit,
        hasCount: true,
        isLoading: false,
        loadingNext: false,
        error: false
      }
    }})
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      let insurance = yield select(getInsuranceState);
      yield put(planError(insurance))
    }
  }
}

export default function* comparisonSagas() {
  yield takeEvery(SELECT_CLIENT, comparisonProfileRequestSaga);
  yield takeEvery(SAVE_COMPARISON_VALUES, newCompanyDataSaga);
  yield takeEvery(DELETE_COMPANY_REQUEST, deleteCompanySaga);
  yield takeEvery(GET_MORE_ADMIN_PLANS_REQUEST, getMoreAdminClientPlansSaga);
  yield debounce(500,[SET_CLIENT_FILTER,CLICK_ADMIN_FILTER_REQUEST_BUTTON], setAdminClientPlansFilterSaga);
}
