import "@babel/polyfill";
import 'whatwg-fetch';
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import "../src/montserrat.css";
import '../src/index.css';
import App from '../src/index.js';
import store, {history} from '../src/store'

cssVars({
  variables: {
    '--primary-color': '#0862f6',
    '--average-color': '#7432ff',
    '--median-color': '#00da91',
    '--popular-color': '#ff9c32',
    '--primary-text': '#2f3c54',
    '--gray2': '#a6b3ca',
    '--primary-background': '#f0f4fa',
    '--secondary-background': '#fff',
    '--border-color': '#d4dce9',
    '--border-extra': '#a6b3ca',
    '--box-shadow': 'rgba(123, 138, 164, 0.29)',
    '--banner-start': 'rgba(8,98,246,0.2)',
    '--banner-end': 'rgba(8,98,246,0.95)',
    '--axis-color': '#f5f5f5',
    '--state-not-selected-color': '#e4eeff',
    '--state-market-selected-color': '#72a4f6',
    '--state-selected-color': '#0862f6',
    '--logo-color': '#364F63',
    '--white': '#fff',
    '--avatar-placeholder': '#D4DCE9',
  },
  watch: true
})

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.body.appendChild(document.createElement('div')),
  )
})
