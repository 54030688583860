const ADD_ERROR = 'ADD_ERROR';
const REMOVE_ERROR = 'REMOVE_ERROR';

let count = 0;
export const addError = (text) => ({type: ADD_ERROR, payload: {id: count++, text}})
export const removeError = id => ({type: REMOVE_ERROR, payload: id})

export default function reducer(previousState = [], {type, payload}){
  switch (type) {
    case ADD_ERROR:
      return [...previousState, payload]
    case REMOVE_ERROR:
      return previousState.filter(toast => toast.id !== payload)
    default:
      return previousState
  }
}
