export const ADD_BENCHMARK_VALUE = "ADD_BENCHMARK_VALUE";
export const ADD_ALL_SELECTIONS = "ADD_ALL_SELECTIONS";
export const REMOVE_BENCHMARK = "REMOVE_BENCHMARK";
export const CHANGE_GROUP_VALUE = "CHANGE_GROUP_VALUE";
export const ADD_MULTIPLE_STATES = "ADD_MULTIPLE_STATES";
export const SET_STATES = "SET_STATES";
export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";
export const ADD_STATE = "ADD_STATE";
export const ADD_MARKET = "ADD_MARKET";
export const ADD_MINOR_INDUSTRY = "ADD_MINOR_INDUSTRY";
export const ADD_INDUSTRY = "ADD_INDUSTRY";
export const ADD_MAJOR_INDUSTRY = "ADD_MAJOR_INDUSTRY";
export const CLEAR_INDUSTRY_SELECTIONS = "CLEAR_INDUSTRY_SELECTIONS";
export const ADD_ALL_MAJOR_INDUSTRIES = "ADD_ALL_MAJOR_INDUSTRIES";

export const addBenchmarkValue = (insurance, category, multi, option) => ({ type: ADD_BENCHMARK_VALUE, payload: {insurance, category, multi, option}});
export const removeBenchmark = (insurance, category, option) => ({type: REMOVE_BENCHMARK, payload: {insurance, category, option}})
export const addAllSelections = (insurance, category) => ({type: ADD_ALL_SELECTIONS, payload: {insurance, category}})
export const handleGroupChange = (insurance, name, value) => ({type: CHANGE_GROUP_VALUE, payload: {insurance, name, value}})
export const clearSelections = (insurance, category) => ({ type: CLEAR_SELECTIONS, payload: {insurance, category} })
export const addStates = (insurance, states) => ({type: ADD_MULTIPLE_STATES, payload: {insurance, states}})
export const setStates = (insurance, states) => ({type: SET_STATES, payload: {insurance, states}})
export const addState = (insurance, state) => ({type: ADD_STATE, payload: {insurance, state}})
export const addMarket = (insurance, state, market) => ({type: ADD_MARKET, payload: {insurance, state, market}})
export const addIndustry = (insurance, industry) => ({type: ADD_INDUSTRY, payload: {insurance, industry}})
export const addMinorIndustry = (insurance, industry, minor_industry) => ({type: ADD_MINOR_INDUSTRY, payload: {insurance, industry, minor_industry}})
export const addMajorIndustry = (insurance, major_industry) => ({type: ADD_MAJOR_INDUSTRY, payload: {insurance, major_industry}})
export const clearAllIndustrySelections = (insurance) => ({type: CLEAR_INDUSTRY_SELECTIONS, payload: insurance})
export const addAllMajorIndustries = (insurance) => ({type: ADD_ALL_MAJOR_INDUSTRIES, payload: insurance})

const initialState = {
  medical: {
    regions: [],
    group: [],
    major_industry: [],
    industry: [],
    minor_industry: [],
    products: [],
    client: [],
    filter: [],
    markets: []
  },
  dental: {
    regions: [],
    group: [],
    major_industry: [],
    industry: [],
    minor_industry: [],
    products: [],
    client: [],
    filter: [],
    markets: []
  }
};

function makeNewCategoryArray(option, previousArray, multi){
  let newArray;
  if(option.replace){
    let idx = previousArray.findIndex(filter => filter.label === option.replace);
    if(idx !== -1){
      newArray = [...previousArray.slice(0, idx), ...previousArray.slice(idx+1), option]
    } else {
      newArray = [...previousArray, option]
    }
  } else if(multi && previousArray[0] && previousArray[0].label !== "All") {
    newArray = [...previousArray, option]
  } else {
    newArray = [option]
  }
  return newArray;
}

function merge(a1, a2) {
   var hash = {};
   var arr = [];
   for (var i = 0; i < a1.length; i++) {
      if (hash[a1[i].value] !== true) {
        hash[a1[i].value] = true;
        arr[arr.length] = a1[i];
      }
   }
   for (var i = 0; i < a2.length; i++) {
      if (hash[a2[i].value] !== true) {
        hash[a2[i].value] = true;
        arr[arr.length] = a2[i];
      }
   }
   return arr;
}

function removeMarketsOfStates(markets, states){
  var marketsOfAddedStates = []
  var selectedMarkets = [markets]
  for (var i = 0; i < states.length; i++) {
    if(states[i].markets && states[i].markets){
      marketsOfAddedStates.concat(states[i].markets)
    }
  }
  var idxs = []
  for (var i = 0; i < marketsOfAddedStates.length; i++) {
    var idx = selectedMarkets.findIndex(market => market === marketsOfAddedStates[i])
    if(idx > -1){
      idxs.push(idx)
    }
  }
  for (var i = idxs.length; i >= 0; i--) {
    selectedMarkets.splice(idxs[i], 1);
  }
  return selectedMarkets;
}

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case ADD_BENCHMARK_VALUE:
      let oldCategory = previousState[payload.insurance][payload.category];
      let newCategory = makeNewCategoryArray(payload.option, oldCategory, payload.multi);
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          [payload.category]: newCategory
        }
      }
    case ADD_ALL_SELECTIONS:
    return {
      ...previousState,
      [payload.insurance]: {
        ...previousState[payload.insurance],
        [payload.category]: [{value: "all", label: "All"}]
      }
    }
    case CLEAR_SELECTIONS:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          [payload.category]: []
        }
      }
    case REMOVE_BENCHMARK:
      let idx = previousState[payload.insurance][payload.category].findIndex(benchmark => benchmark === payload.option);
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          [payload.category]: [...previousState[payload.insurance][payload.category].slice(0, idx), ...previousState[payload.insurance][payload.category].slice(idx+1)]
        }
      }
    case CHANGE_GROUP_VALUE:
      let group;
      if(previousState[payload.insurance].group[0] && previousState[payload.insurance].group[0].value){
        group = [{...previousState[payload.insurance].group[0], value: {...previousState[payload.insurance].group[0].value, [payload.name]: payload.value}}]
      } else {
        group = [{value: {[payload.name]: payload.value}}]
      }
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          group
        }
      }
    case ADD_MULTIPLE_STATES:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: merge(previousState[payload.insurance].regions, payload.states),
          markets: removeMarketsOfStates(previousState[payload.insurance].markets, payload.states)
        }
      }
    case SET_STATES:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: payload.states
        }
      }
    case ADD_STATE:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: [...previousState[payload.insurance].regions, payload.state],
          markets: previousState[payload.insurance].markets.filter(market => market.state_id !== payload.state.value)
        }
      }
    case ADD_MARKET:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: previousState[payload.insurance].regions.filter(region => region.value !== payload.market.state_id),
          markets: [...previousState[payload.insurance].markets, payload.market]
        }
      }
    case ADD_ALL_MAJOR_INDUSTRIES:
      return {
        ...previousState,
        [payload]: {
          ...previousState[payload],
          major_industry: [{value: "all", label: "All"}],
          industry: [],
          minor_industry: []
        }
      }
    case ADD_MAJOR_INDUSTRY:
      var newMajorIndustryIds;
      if(previousState[payload.insurance].major_industry[0] && previousState[payload.insurance].major_industry[0].label === "All"){
        newMajorIndustryIds = [payload.major_industry]
      } else {
        newMajorIndustryIds = [...previousState[payload.insurance].major_industry, payload.major_industry]
      }
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          major_industry: newMajorIndustryIds,
          industry: [],
          minor_industry: []
        }
      }
    case ADD_INDUSTRY:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          major_industry: [],
          industry: [...previousState[payload.insurance].industry, payload.industry],
          minor_industry: []
        }
      }
    case ADD_MINOR_INDUSTRY:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          major_industry: [],
          industry: [],
          minor_industry: [...previousState[payload.insurance].minor_industry.filter(minor_industry => minor_industry.industry_id === payload.minor_industry.industry_id), payload.minor_industry]
        }
      }
    case CLEAR_INDUSTRY_SELECTIONS:
      return {
        ...previousState,
        [payload]: {
          ...previousState[payload],
          major_industry: [],
          industry: [],
          minor_industry: []
        }
      }
    case "LOGIN_REQUEST__SUCCESS":
      return initialState;
      break;
    case "LOGOUT":
      return initialState;
      break;
    default:
      return previousState;
  }
}
