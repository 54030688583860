function TokenExpirationException(){
  this.name = 'TokenExpirationException'
}

function statusCheck(response){
  if(response.status === 401){
    throw new TokenExpirationException;
  }
  return response
}

const API = (function(){
  var _element = document.querySelector('meta[name=csrf-token]');
  var _csrf_token = _element && _element.getAttribute("content");
  var _token = null;
  return {
        getCSRF: () => _csrf_token,
        setToken: token => {_token = token},
        get: (url) => fetch(`${process.env.BASEURL}/${url}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${_token}`
              }
            }
          ).then(statusCheck).then(response => response.json()),
        post: (url, body) => fetch(`${process.env.BASEURL}/${url}`, {
              method: 'POST',
              headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${_token}`,
                'X-CSRF-Token': _csrf_token
              },
              body: JSON.stringify(body)
            }
          ).then(statusCheck).then(response => response.json()),
        patch: (url, body) => fetch(`${process.env.BASEURL}/${url}`, {
            method: 'PATCH',
            headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer ${_token}`,
              'X-CSRF-Token': _csrf_token
            },
            body: JSON.stringify(body)
          }
        ).then(statusCheck).then(response => response.json()),
        delete: (url) => fetch(`${process.env.BASEURL}/${url}`, {
              method: 'DELETE',
              headers: {
                'Authorization': `Bearer ${_token}`
              }
            }
          ).then(statusCheck)
    };
})();

export default API;
