import { createStore, applyMiddleware, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import API from '../utils/API';
import display from './display';
import data from './data';
import graph from './graph';
import user from './user';
import benchmarks from './benchmarks';
import states from './states';
import clients from './clients';
import errors from './errors';
import comparison from './comparison';
import adminPlans from './adminPlans';
import clientRegionFilter from './clientRegionFilter';
import options from './options';
import pdf from './pdf';
import form from './form';
import rootSaga from '../sagas';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

function getPreloadedState(){
  let preloadedState = {};
  let user = sessionStorage.getItem("user");
  if(user){
    let userObj = JSON.parse(user);
    preloadedState.user = userObj;
    API.setToken(userObj.token)
  }
  let data = sessionStorage.getItem('data');
  if(data){
    preloadedState.data = JSON.parse(data)
  }
  let pdf = sessionStorage.getItem('pdf');
  if(pdf){
    preloadedState.pdf = { list:JSON.parse(pdf), isLoading: false, current: null };
  }
  let options = sessionStorage.getItem('options');
  if(options){
    preloadedState.options = JSON.parse(options)
  }
  let clients = sessionStorage.getItem('clients');
  if(clients){
    const parsedClients = JSON.parse(clients)
    preloadedState.clients = parsedClients;
    /*
    preloadedState.adminPlans = {
      medical: {
        ...parsedClients.medical.uhc,
        limit: 100,
        count: 0
      },
      dental: {
        ...parsedClients.dental.uhc,
        limit: 100,
        count: 0,
      },
      isLoading: true
    }
    */
  }
  return preloadedState;
}

const store = createStore(
  combineReducers({
    display,
    data,
    graph,
    user,
    benchmarks,
    states,
    clients,
    errors,
    comparison,
    adminPlans,
    clientRegionFilter,
    options,
    pdf,
    form,
    router: connectRouter(history)
  }),
  getPreloadedState(),
  applyMiddleware(routerMiddleware(history), sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export default store;
