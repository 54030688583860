import React from 'react';
import styles from './index.module.css'

const ComparisonToggle = ({active, toggleComparison}) =>
  <div className={styles.container} onClick={toggleComparison}>
    <div>
      Comparison
      &nbsp;
    </div>
    <div className={active ? styles.switch : styles.switch+" "+styles.inactive}>
      &nbsp;
      On
      &nbsp;
    </div>
    <div className={styles.inactive}>&nbsp;/&nbsp;</div>
    <div className={!active ? styles.switch : styles.switch+" "+styles.inactive}>
      &nbsp;
      Off
    </div>
  </div>

export default ComparisonToggle;
