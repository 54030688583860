import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';

const Button = ({
  to,
  onClick,
  children,
  color,
  size,
  className
}) => {
  let ComponentWrapper = to ? Link : 'button'
  return(
    <ComponentWrapper
      to={to ? to : null}
      onClick={onClick ? onClick : null}
      className={getButtonClassName(color, size, className)}
    >
      {children}
    </ComponentWrapper>
  )
}

function getButtonClassName(color, size, classNameProp){
  let className = styles.container;
  if(color === 'secondary'){
    className += " "+styles.secondary
  } else {
    className += " "+styles.primary
  }

  if(size === 'xs'){
    className += " "+styles.xs
  } else if(size === 's'){
    className += " "+styles.s
  } else if(size === 'm'){
    className += " "+styles.m
  } else {
    className += " "+styles.l
  }

  if(classNameProp){
    className += " "+classNameProp;
  }
  return className;
}

export default Button;
