import React from 'react';

const UHCLogo = ({className}) =>
<svg height="42%" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504 103">
<g>
	<g>
		<path fill="#7B8AA4" d="M68.3,43.4h16.4v2.2c-4.4,0.3-4.8,1.1-4.8,4.6v29.4c0,6.3,3.6,9.6,8.7,9.6c5.8,0,9.4-3.7,9.4-9.8
			V50.9c0-3.5-1.1-5-5.3-5.3v-2.2h13.1v2.2c-4.2,0.3-5.3,1.8-5.3,5.3V78c0,8.5-3.7,13.4-13.2,13.4c-9.6,0-14.1-5.2-14.1-13.7V50.2
			c0-3.5-0.4-4.4-4.9-4.6V43.4z"/>
		<path fill="#7B8AA4" d="M104.7,90.3v-2.2c3.3-0.1,4.4-1.4,4.4-3.4V63.4c0-3-0.8-3.4-4.5-3.6v-2.1l10-0.4v6.6h0.1
			c1.8-3.8,4.5-7,9.2-7c5.7,0,8.3,3.7,8.3,9.7v18.2c0,2.3,1,3.3,4.2,3.4v2.2h-10.1V66c0-4.5-1.7-6-4-6c-3.6,0-7.3,4.8-7.3,11.5v13.2
			c0,2,1.1,3.3,4.4,3.4v2.2H104.7z"/>
		<path fill="#7B8AA4" d="M148.2,47.1c0,2.6-1.4,4.4-3.5,4.4c-2,0-3.5-1.8-3.5-4.4c0-2.6,1.4-4.4,3.5-4.4
			C146.7,42.7,148.2,44.5,148.2,47.1z M137.9,90.3v-2.2c3.3-0.1,4.4-1.4,4.4-3.4V63c0-2.9-0.8-3.4-4.4-3.6v-2.2l10.2-0.4v27.9
			c0,2.1,1.1,3.3,4.4,3.4v2.2H137.9z"/>
		<path fill="#7B8AA4" d="M163.1,45.1v12.6h8.2v2.6h-8.2v24.1c0,3,1,4.2,2.9,4.2c2.3,0,4-1.4,4-8.7V78h2.2v3.2
			c0,6.9-2.5,10-7.7,10c-4.5,0-7.2-2.8-7.2-8.3V60.2H153v-2c4.9-2,7.5-4.4,7.9-13.1H163.1z"/>
		<path fill="#7B8AA4" d="M200.1,73.8c0-9.8-4.8-16.9-12.3-16.9c-7.7,0-13.2,7.1-13.2,17.1c0,8.6,5.6,17.2,14.1,17.2
			c7.6,0,11-5.9,11.6-12l-2.2-0.4c-0.7,4.7-2.8,9.9-8.7,9.9c-4.6,0-8.1-5.2-8.1-14.8L200.1,73.8L200.1,73.8z M181.2,71.2
			c0-8.7,2.6-12.2,6.5-12.2c3.6,0,5.7,3.4,5.7,12.2H181.2z"/>
		<path fill="#7B8AA4" d="M218.6,45.5c3.5,0.1,4.4,0.6,4.4,3.5v12.3l-0.1,0.1c-1.4-2.7-3.7-4.6-6.9-4.6
			c-7.2,0-12.6,7.4-12.6,17.3c0,10.7,5.7,17,11.8,17c4.2,0,6.4-2.4,7.9-5.6h0.1v4.8l9.6-0.4v-2.2c-2.7,0.1-4-1-4-4.7V43l-10.3,0.4
			v2.1H218.6z M210.2,74.2c0-10.5,2.4-14.8,6.2-14.8c4.2,0,6.7,5.1,6.7,14.8c0,11.8-3.6,14.8-7.2,14.8
			C212.4,88.9,210.2,84.7,210.2,74.2z"/>
		<path fill="#7B8AA4" d="M234.6,90.3v-2.2c4.1-0.3,4.8-1.1,4.8-4.7V50.2c0-3.5-0.7-4.4-4.8-4.6v-2.2h16.5v2.2
			c-4.2,0.3-5,1.1-5,4.6v14.1h14.7V50.2c0-3.5-0.8-4.4-5-4.6v-2.2h16.5v2.2c-4.1,0.3-4.8,1.1-4.8,4.6v33.3c0,3.6,0.7,4.4,4.8,4.7
			v2.2h-16.5v-2.2c4.2-0.3,5-1.1,5-4.7V66.9h-14.7v16.5c0,3.6,0.8,4.4,5,4.7v2.2H234.6z"/>
		<path fill="#7B8AA4" d="M298.8,73.8c0-9.8-4.8-16.9-12.4-16.9c-7.7,0-13.2,7.1-13.2,17.1c0,8.6,5.6,17.2,14.1,17.2
			c7.6,0,11-5.9,11.6-12l-2.2-0.4c-0.7,4.7-2.8,9.9-8.7,9.9c-4.6,0-8.1-5.2-8.1-14.8L298.8,73.8L298.8,73.8z M280,71.2
			c0-8.7,2.6-12.2,6.5-12.2c3.6,0,5.7,3.4,5.7,12.2H280z"/>
		<path fill="#7B8AA4" d="M325.6,80.9c-0.1,4.9-0.7,6.4-2,6.4c-0.9,0-1.4-0.9-1.4-2.9v-19c0-5.1-2.9-8.6-10.6-8.6
			c-5.9,0-10.3,3.7-10.3,8.3c0,2.7,1.4,4.1,3.3,4.1s3.4-1.5,3.4-4c0-2-0.7-3.6-2.9-3.8c1.4-1.7,3.4-2.5,5.8-2.5
			c3.6,0,5.4,1.7,5.4,5.6v5.6c-5.9,2.2-16.6,5.4-16.6,13.2c0,4.5,3.1,7.7,8.3,7.7c4.8,0,6.8-2.2,8.5-5.3h0.1
			c0.4,3.7,2.3,5.3,5.5,5.3c4.2,0,5.7-3.1,5.7-10.2L325.6,80.9z M316.4,76.8c0,7.7-3.1,11.8-6.6,11.8c-2.1,0-3.8-1.8-3.8-5.4
			c0-5.8,4.9-8.4,10.4-10.3V76.8z"/>
		<path fill="#7B8AA4" d="M326.9,90.3v-2.2c3.3-0.1,4.4-1.4,4.4-3.4V49.2c0-2.3-1.2-3.5-4.5-3.7v-2.2l10.4-0.4v41.8
			c0,2,1.1,3.3,4.4,3.4v2.2H326.9z"/>
		<path fill="#7B8AA4" d="M351.4,45.1v12.6h8.2v2.6h-8.2v24.1c0,3,1,4.2,2.9,4.2c2.3,0,4-1.4,4-8.7V78h2.2v3.2
			c0,6.9-2.5,10-7.7,10c-4.5,0-7.2-2.8-7.2-8.3V60.2h-4.2v-2c4.9-2,7.5-4.4,7.9-13.1H351.4z"/>
		<path fill="#7B8AA4" d="M360.2,90.3v-2.2c3.3-0.1,4.4-1.4,4.4-3.4V49.1c0-2.9-0.8-3.4-4.5-3.5v-2.1l10.4-0.4V63h0.1
			c1.4-3,4-6.2,8.5-6.2c5.3,0,8.5,3.7,8.5,9.7v18.2c0,2,1.2,3.3,4.2,3.4v2.2h-10.1v-24c0-4.8-1.7-6.3-4.2-6.3
			c-3.4,0-7.2,4.5-7.2,11.1v13.7c0,2,1.1,3.3,4.4,3.4v2.2L360.2,90.3L360.2,90.3z"/>
		<path fill="#7B8AA4" d="M417.3,79c-0.6,6.5-3.6,12.2-11.1,12.2c-6.9,0-13.3-6.2-13.3-16.3c0-8.5,5.9-18.2,14.2-18.2
			c5.8,0,9.5,3.6,9.5,8.9c0,3.3-1,5-3.5,5c-1.9,0-3.2-1.6-3.2-4.1c0-2.9,1-4.1,2.8-4.1c0.5,0,0.8,0.2,1,0.3l0.1-0.1
			c-1-2.5-3.2-3.8-5.7-3.8c-5.1,0-8.5,5.1-8.5,14.8c0,11.6,3.6,14.8,7.6,14.8c5.5,0,7.3-5.1,8.1-9.9L417.3,79z"/>
		<path fill="#7B8AA4" d="M445.6,81c-0.1,5-0.7,6.5-2,6.5c-0.9,0-1.4-0.9-1.4-2.9V65.4c0-5.2-2.9-8.6-10.7-8.6
			c-6,0-10.3,3.7-10.3,8.4c0,2.8,1.4,4.1,3.3,4.1s3.4-1.5,3.4-4c0-2-0.7-3.7-2.9-3.9c1.4-1.7,3.4-2.5,5.8-2.5c3.7,0,5.5,1.7,5.5,5.7
			v5.7c-5.9,2.2-16.7,5.4-16.7,13.2c0,4.5,3.1,7.8,8.4,7.8c4.8,0,6.9-2.3,8.5-5.4h0.1c0.4,3.7,2.3,5.4,5.5,5.4
			c4.2,0,5.6-3.1,5.6-10.3H445.6z M436.4,76.7c0,7.8-3.2,11.9-6.7,11.9c-2.1,0-3.8-1.8-3.8-5.4c0-5.8,5-8.5,10.5-10.4V76.7z"/>
		<path fill="#7B8AA4" d="M448.6,90.4v-2.1c2.9-0.1,4-1.4,4-3.4V66.1c0-4.7-0.8-6.2-4.2-6.3v-2.2l9.4-0.4v7.9h0.1
			c0.9-3.6,3.3-8.3,7.4-8.3c2.5,0,5,2.1,5,5.9c0,3-1.4,4.5-3.2,4.5c-1.5,0-3.1-1-3.1-4.1c0-1.7,1-3.4,2.9-3.4
			c-0.3-0.5-1-0.8-1.8-0.8c-4.6,0.1-6.7,10.1-6.7,16.1v9.8c0,2.1,1.1,3.3,5.7,3.4v2.1L448.6,90.4L448.6,90.4z"/>
		<path fill="#7B8AA4" d="M495.9,73.9c0-10-4.8-17.1-12.4-17.1c-7.7,0-13.2,7.1-13.2,17.3c0,8.5,5.6,17.2,14.1,17.2
			c7.7,0,11-6,11.6-12l-2.1-0.4c-0.7,4.7-2.8,9.8-8.8,9.8c-4.6,0-8.1-5.2-8.1-14.7L495.9,73.9L495.9,73.9z M477,71.3
			c0-8.9,2.6-12.3,6.5-12.3c3.6,0,5.7,3.5,5.7,12.3H477z"/>
		<path fill="#7B8AA4" d="M497.4,51.5h1.2c0.6,0,0.9,0.5,0.9,0.9c0,0.5-0.3,1-0.9,1h-1.2V51.5z M497.4,54.1h1
			c0.3,0,0.8,0,0.9,0.7c0.1,0.8,0,1.6,0.2,2h0.9c-0.1-0.4-0.2-1.3-0.2-1.5c0-1.2-0.3-1.4-1-1.5c0.8-0.1,1.3-0.7,1.3-1.4
			c0-0.7-0.5-1.5-1.6-1.5h-2.3v5.9h1v-2.7H497.4z M498.3,48.7c-2.8,0-5.1,2.3-5.1,5.1c0,2.9,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1
			S501.1,48.7,498.3,48.7z M498.3,49.5c2.4,0,4.3,1.9,4.3,4.3c0,2.4-1.9,4.4-4.3,4.4c-2.4,0-4.4-1.9-4.4-4.4
			C494,51.4,495.9,49.5,498.3,49.5z"/>
		<path fill="#7B8AA4" d="M8.8,90.3L8.8,90.3H9h0.1h0.1h0.1h0.1h0.1c0.1,0,0.1,0,0.2,0h0.1c0.1,0,0.1,0,0.2,0h0.1
			c0.1,0,0.1,0,0.2,0h0.1c0.1,0,0.1,0,0.2,0h0.1c0.1,0,0.1,0,0.2-0.1H11c0.1,0,0.2,0,0.2-0.1h0.1c0.1,0,0.2-0.1,0.2-0.1
			s0.1,0,0.1-0.1c0.1,0,0.2-0.1,0.2-0.1s0.1,0,0.1-0.1c0.1,0,0.2-0.1,0.2-0.1s0.1,0,0.1-0.1c0.1,0,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2L13,89c0.1-0.1,0.2-0.1,0.3-0.2l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2l0.1-0.1
			c0.1-0.1,0.2-0.1,0.3-0.2l0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2l0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.1-0.2
			c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3s0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3
			s0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3s0.1-0.2,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.3s0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.4
			c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.4s0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.2-0.4s0.1-0.3,0.2-0.4
			c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5
			c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.4c0.1-0.3,0.2-0.7,0.3-1c1.4-4.9,1.7-14.8,1.7-21.4V14.7l-20.5,11v43.4
			c0,16.5,3,20.5,6.7,21.2c0,0,0,0-0.1,0H8.8z"/>
		<path fill="#7B8AA4" d="M14,94.8c8.1-1.1,12.8-10.1,13.9-14.5C29.7,74.2,30,66.6,30,58V10l-8.7,4.7l4.6,1v38.5
			c0,9.9-0.2,18.6-1.8,24.4c-2.3,8.1-6.4,11.4-9.3,12.5l-7.3-0.8c0,0,0,0-0.1,0c0.8,2.3,2.6,4.1,4.7,4.4L14,94.8z"/>
		<path fill="#7B8AA4" d="M12.2,94.7c0.7,2,2.6,3.7,4.6,4.1l1.9,0.2c8.5-0.1,14-8.3,16-12.7c2.9-6.4,4.3-15.5,4.3-28.6
			V5.3L30,10l4.7,1v44.6c0,11.1-0.4,20.6-3.1,27.7c-2.4,6.1-6.6,10.8-11.8,12.1l-5.7-0.7H12.2z"/>
		<path fill="#7B8AA4" d="M38.9,5.3l4.8,1.1v49.3c0,15.1-1.5,25.5-4.9,32.5c-3.2,6.5-8.5,10.7-13.5,11.6L18.7,99
			l-1.9-0.2c1.6,2.7,4.3,4.1,7.3,4.2c4.1,0.2,7.4-1.6,10.6-3.7c10-6.9,13.2-22.3,13.2-43.2V0.5L38.9,5.3z"/>
	</g>
</g>
</svg>

export default UHCLogo;
