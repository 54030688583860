import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import PrivateLayout from '../components/PrivateLayout';
import { connect } from 'react-redux';


const PrivateRoute = ({userState, noFooter, component: Component, ...rest}) =>
  <Route {...rest} render={matchProps => {
    if(!userState){
      return(
        <Redirect to="/sign-in" />
      )
    }

    return(
      <PrivateLayout noFooter={noFooter}>
        <Component {...matchProps} />
      </PrivateLayout>
    )
  }} />

export default connect(
  ({user}) => ({userState: user && user.email})
)(PrivateRoute);
