
export const BASE_URL = "https://cors-anywhere.herokuapp.com/https://clm3.jmg.mn"
export const API_URL = BASE_URL+"/api";

export function findMedian(numbers) {
    var median = 0, count = numbers.length;
    numbers.sort();

    if (count % 2 === 0) {  // is even
        median = (numbers[count / 2 - 1] + numbers[count / 2]) / 2;
    } else { // is odd
        median = numbers[(count - 1) / 2];
    }

    return median;
}

export function fixedPointAlgorithm(data, comparison){
  let fixedPoint, highest, lowest;
  if(comparison){
    fixedPoint = comparison;
    highest = Math.max(data.average, data.median, data.popular, comparison);
    lowest = Math.min(data.average, data.median, data.popular, comparison);
  } else {
    fixedPoint = findMedian(Object.values(data));
    highest = Math.max(data.average, data.median, data.popular);
    lowest = Math.min(data.average, data.median, data.popular);
  }
  let highDif = highest - fixedPoint;
  let lowDif = fixedPoint - lowest;
  let delta = Math.max(highDif, lowDif);
  let deltaMin = highest * 0.02;
  if(delta < deltaMin){
    delta = deltaMin
  }
  let scale = 2;
  let scaledDelta = delta * scale;
  let lowerLimit = fixedPoint - scaledDelta;
  let range = scaledDelta * 2;
  let average = (((data.average - lowerLimit) / range) * 100);
  let median = (((data.median - lowerLimit) / range) * 100);
  let popular = (((data.popular - lowerLimit) / range) * 100);
  return { average, median, popular };
}

export function fixedPointAlgorithmOld(data){
  let highest = Math.max(data.average, data.median, data.popular, data.comparison);
  let lowest = Math.min(data.average, data.median, data.popular, data.comparison);
  let highDif = highest - data.comparison;
  let lowDif = data.comparison - lowest;
  let delta = Math.max(highDif, lowDif);
  let scale = 2;
  let scaledDelta = delta * scale;
  let lowerLimit = data.comparison - scaledDelta;
  let range = scaledDelta * 2;
  let average = (((data.average - lowerLimit) / range) * 100);
  let median = (((data.median - lowerLimit) / range) * 100);
  let popular = (((data.popular - lowerLimit) / range) * 100);
  return { comparison: 50, average, median, popular };
}

export function chopAlgorithm(data, comparisonData){
  let highest = Math.max(data.average, data.median, data.popular, comparisonData);
  let lowest = Math.min(data.average, data.median, data.popular, comparisonData);
  let subtraction = lowest * 0.5;
  highest -= subtraction;
  let comparison = (((comparisonData - subtraction) / highest) * 100);
  let average = (((data.average - subtraction) / highest) * 100);
  let median = (((data.median - subtraction) / highest) * 100);
  let popular = (((data.popular - subtraction) / highest) * 100);
  return { comparison, average, median, popular };
}
export function regularAlgorithm(data, comparisonData){
  let highest = Math.max(data.average, data.median, data.popular, comparisonData);
  let comparison = ((comparisonData / highest) * 100);
  let average = ((data.average / highest) * 100);
  let median = ((data.median / highest) * 100);
  let popular = ((data.popular / highest) * 100);
  return { comparison, average, median, popular };
}

export function getCashFormat(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    let i = parseInt(amount = Math.abs(Number(amount) || 0)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return "$"+(j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
  } catch (e) {
    console.log(e)
  }
}

export function makeGroupLabel(group,range){
  if(group && group[0] && group[0].value){
    if(group[0].label === "All"){
      return "All"
    } else if(group[0].value.min_value && group[0].value.max_value){
      return `${group[0].value.min_value} - ${group[0].value.max_value}`
    } else if(group[0].value.min_value){
      return `Min. ${group[0].value.min_value}`
    } else if(group[0].value.max_value){
      return `Max. ${group[0].value.max_value}`
    }
  }
  return "All";
}
export const makeValueString = (value,format) => {
  if(value || value === 0){
    return format ? format(value) : value
  }
  return '--'
}
export function getPercentFormat(value){
  if(value === 0){
    return "0%"
  }
  return value+"%"
}

export const panelMap = {
  family_deductible: { label: "Deductible", category: "Family", valueFormatter: getCashFormat },
  family_pocket: { label: "Out of Pocket Max.", category: "Family", valueFormatter: getCashFormat },
  family_coinsurance: { label: "Coinsurance", category: "Family", valueFormatter: getPercentFormat },
  individual_deductible: { label: "Deductible", category: "Individual", valueFormatter: getCashFormat },
  individual_pocket: { label: "Out of Pocket Max.", category: "Individual", valueFormatter: getCashFormat },
  individual_coinsurance: { label: "Coinsurance", category: "Individual", valueFormatter: getPercentFormat },
  deductible_family: { label: "Family", category: "Deductible", valueFormatter: getCashFormat },
  deductible_individual: { label: "Individual", category: "Deductible", valueFormatter: getCashFormat },
  max_max: { label: "Annual Max.", category: "Annual Maximum", valueFormatter: getCashFormat },
  services_preventive: { label: "Preventive & Diagnostic", category: "Dental Services", valueFormatter: getPercentFormat },
  services_dentures: { label: "Dentures", category: "Dental Services", valueFormatter: getPercentFormat },
  services_crowns: { label: "Crowns", category: "Dental Services", valueFormatter: getPercentFormat },
  services_restorative: { label: "Minor Restorative", category: "Dental Services", valueFormatter: getPercentFormat },
  services_extractions: { label: "Simple Extractions", category: "Dental Services", valueFormatter: getPercentFormat },
  services_implants: { label: "Dental Implants", category: "Dental Services", valueFormatter: getPercentFormat },
  ortho_max: { label: "Ortho Lifetime Max.", category: "Orthodontic Care", valueFormatter: getCashFormat },
  ortho_care: { label: "Orthodontic Care", category: "Orthodontic Care", valueFormatter: getPercentFormat },
  specialties_perio: { label: "Periodontic Maintenance", category: "Specialties", valueFormatter: getPercentFormat },
  specialties_periononsurg: { label: "Non Surgical Periodontics", category: "Specialties", valueFormatter: getPercentFormat },
  specialties_periosurg: { label: "Surgical Periodontics", category: "Specialties", valueFormatter: getPercentFormat },
  specialties_endo: { label: "Endodontics", category: "Specialties", valueFormatter: getPercentFormat },
  specialties_oral: { label: "Oral Surgery", category: "Specialties", valueFormatter: getPercentFormat },
  specialties_pros: { label: "Fixed Prosthetics", category: "Specialties", valueFormatter: getPercentFormat }
}

export function makeClientMap(newPlans, oldPlans){
  return newPlans.reduce((acc,[company,type_id,high,low,quote_id,type_name]) => {
    if(acc.map[company]){
      acc.map[company].push({quote_id, name: `${type_name} $${Number(high)} $${Number(low)} Plan`})
    } else {
      acc.map[company] = [{quote_id, name: `${type_name} $${Number(high)} $${Number(low)} Plan`}]
      acc.array.push(company)
    }
    return acc;
  },{map: (oldPlans && oldPlans.map) ? ({...oldPlans.map}) : ({}), array: (oldPlans && oldPlans.array) ? [...oldPlans.array] : []})
}

export function classNames(){
  return [...arguments].filter(s => typeof s === 'string').join(' ');
};
