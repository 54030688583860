import React from 'react';
import { connect } from 'react-redux';

import { openModal } from '../store/display';

class ModalTrigger extends React.PureComponent {
  handleClick = () => {
    if(this.props.onClick){
      this.props.onClick();
    }
    this.props.openModal(this.props.modal)
  }
  render(){
    return(
      <div className={this.props.className} onClick={this.handleClick}>
        {this.props.children}
      </div>
    )
  }
}

export default connect(
  null,
  {openModal}
)(ModalTrigger);
