import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import cssVars from 'css-vars-ponyfill';
import PrivateRoute from './containers/PrivateRoute';
import PublicRoute from './containers/PublicRoute';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './containers/Header';
import ScreenLock from './components/ScreenLock';
import loadinggif from './assets/images/loading.gif'
const LandingPage = lazy(() => import("./components/LandingPage"));
const SignInPage = lazy(() => import("./containers/SignInPage"));
const RegistrationPageOne = lazy(() => import("./containers/RegistrationPageOne"));
const RegistrationPageTwo = lazy(() => import("./containers/RegistrationPageTwo"));
const RequestIdForm = lazy(() => import("./containers/RequestIdForm"));
const SuccessPage = lazy(() => import("./components/SuccessPage"));
const PasswordRecoveryPage = lazy(() => import("./containers/PasswordRecoveryPage"));
const SetPasswordPage = lazy(() => import("./containers/SetPasswordPage"));
const DashboardPage = lazy(() => import("./components/DashboardPage"));
const ComparisonPage = lazy(() => import("./components/ComparisonPage"));
const ArchivePage = lazy(() => import("./containers/ArchivePage"));
const ContactPage = lazy(() => import("./containers/ContactPage"));
const TutorialPage = lazy(() => import("./components/TutorialPage"));
const ProfilePage = lazy(() => import("./components/ProfilePage"));
const ProfilePasswordPage = lazy(() => import("./components/ProfilePasswordPage"));
const BenchmarkPage = lazy(() => import("./components/BenchmarkPage"));
const PrivacyPage = lazy(() => import("./components/PrivacyPage"));
const TOS = lazy(() => import("./components/TOS"));
const Modals = lazy(() => import("./components/Modals"));
const ErrorToaster = lazy(() => import("./components/ErrorToaster"));
const Loader = lazy(() => import("./containers/Loader"));
const Impersonate = lazy(() => import("./containers/Impersonate"));
const ThemeController = lazy(() => import("./containers/ThemeController"));

cssVars({
  variables: {
    '--primary-color': '#0862f6',
    '--average-color': '#7432ff',
    '--median-color': '#00da91',
    '--popular-color': '#ff9c32',
    '--primary-text': '#2f3c54',
    '--header-color': "#fff",
    '--gray2': '#a6b3ca',
    '--primary-background': '#f0f4fa',
    '--secondary-background': '#fff',
    '--border-color': '#d4dce9',
    '--border-extra': '#a6b3ca',
    '--box-shadow': 'rgba(123, 138, 164, 0.29)',
    '--banner-start': 'rgba(8,98,246,0.2)',
    '--banner-end': 'rgba(8,98,246,0.95)',
    '--axis-color': '#f5f5f5',
    '--state-not-selected-color': '#e4eeff',
    '--state-market-selected-color': '#72a4f6',
    '--state-selected-color': '#0862f6',
    '--state-stroke-color': '#e6eaf4',
    '--logo-color': '#364F63',
    '--white': '#fff',
    '--avatar-placeholder': '#D4DCE9'
  },
  watch: true
})

const App = () =>
  <>
    <Header />
    <ErrorBoundary>
    <Suspense fallback={<div className="loader"><img src={loadinggif} /></div>}>
      <PublicRoute exact path="/" component={LandingPage} />
      <PublicRoute path="/sign-in" component={SignInPage} />
      <PublicRoute path="/register/one" component={RegistrationPageOne} />
      <PublicRoute path="/register/two" component={RegistrationPageTwo} />
      <PublicRoute path="/request-agency-id" component={RequestIdForm} />
      <Route path="/success" component={SuccessPage} />
      <PublicRoute path="/recovery" component={PasswordRecoveryPage} />
      <PublicRoute path="/set-password" component={SetPasswordPage} />
      <PrivateRoute path="/dashboard" component={DashboardPage} />
      <PrivateRoute path="/comparison" component={ComparisonPage} />
      <PrivateRoute path="/archive" component={ArchivePage} />
      <Route path="/contact" component={ContactPage} />
      <PrivateRoute path="/tutorials" component={TutorialPage} />
      <PrivateRoute exact path="/profile" component={ProfilePage} />
      <PrivateRoute path="/profile/password" component={ProfilePasswordPage} />
      <PrivateRoute noFooter path="/benchmarks" component={BenchmarkPage} />
      <Route path="/tos" component={TOS} />
      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/impersonate" component={Impersonate} />
      <ThemeController />
      <Modals />
      <ErrorToaster />
      <Loader />
    </Suspense>
    </ErrorBoundary>
    <ScreenLock />
  </>

export default App;
