export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST__SUCCESS = "LOGIN_REQUEST__SUCCESS";
export const LOGIN_REQUEST__FAILURE = "LOGIN_REQUEST__FAILURE";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_REQUEST__SUCCESS = "UPDATE_PROFILE_REQUEST__SUCCESS";
export const UPDATE_PROFILE_REQUEST__FAILURE = "UPDATE_PROFILE_REQUEST__FAILURE";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_REQUEST__SUCCESS = "UPDATE_PASSWORD_REQUEST__SUCCESS";
export const UPDATE_PASSWORD_REQUEST__FAILURE = "UPDATE_PASSWORD_REQUEST__FAILURE";
export const GET_AGENCY_FROM_ID_REQUEST = "GET_AGENCY_FROM_ID_REQUEST";
export const GET_AGENCY_FROM_ID_REQUEST__SUCCESS = "GET_AGENCY_FROM_ID_REQUEST__SUCCESS";
export const GET_AGENCY_FROM_ID_REQUEST__FAILURE = "GET_AGENCY_FROM_ID_REQUEST__FAILURE";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_REQUEST__SUCCESS = "CREATE_USER_REQUEST__SUCCESS";
export const CREATE_USER_REQUEST__FAILURE = "CREATE_USER_REQUEST__FAILURE";
export const PASSWORD_RESET_EMAIL_REQUEST = "PASSWORD_RESET_EMAIL_REQUEST";
export const CONTACT_FORM_REQUEST = "CONTACT_FORM_REQUEST";
export const AGENCY_ID_EMAIL_REQUEST = "AGENCY_ID_EMAIL_REQUEST";
export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const UPDATE_HIDE_WIZARD_REQUEST = "UPDATE_HIDE_WIZARD_REQUEST";
export const ADMIN_IMPERSONATE_REQUEST = "ADMIN_IMPERSONATE_REQUEST";
export const CROP_AVATAR = "CROP_AVATAR";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const CANCEL_AVATAR_UPDATE = "CANCEL_AVATAR_UPDATE";

export const updateAvatar = (avatar) => ({type: UPDATE_AVATAR, payload: avatar})
export const cancelAvatarUpdate = () => ({type: CANCEL_AVATAR_UPDATE})
export const cropAvatar = (avatar) => ({type: CROP_AVATAR, payload: avatar});
export const updateAvatarRequest = () => ({type: UPDATE_AVATAR_REQUEST})

export const login = (email, password) => ({type: LOGIN_REQUEST, payload: {email, password}})

export const logout = () => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('pdf');
  sessionStorage.removeItem('clients');
  sessionStorage.removeItem('options');
  return({type: LOGOUT});
}

export const loadUser = (payload) => ({type: LOGIN_REQUEST__SUCCESS, payload})

export const updateProfile = () => ({type: UPDATE_PROFILE_REQUEST});

export const updatePassword = () => ({type: UPDATE_PASSWORD_REQUEST});

export const beginRegistration = () => ({type: GET_AGENCY_FROM_ID_REQUEST});

export const createUser = () => ({type: CREATE_USER_REQUEST});

export const sendPasswordResetEmail = () => ({type: PASSWORD_RESET_EMAIL_REQUEST})

export const submitContactForm = () => ({type: CONTACT_FORM_REQUEST})

export const requestAgencyId = () => ({type: AGENCY_ID_EMAIL_REQUEST})

export const setPassword = (verif) => ({type: SET_PASSWORD_REQUEST, payload: verif })

export const updateHideWizardSetting = (hideWizardBool) => ({type: UPDATE_HIDE_WIZARD_REQUEST, payload: hideWizardBool })

export const impersonateRequest = (switchCode) => ({type: ADMIN_IMPERSONATE_REQUEST, payload: switchCode });

const initialState = {email: null, name: null, company: null, avatar: null, token: null, error: null};

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case LOGIN_REQUEST__SUCCESS:
      return {
        ...initialState,
        ...payload.user
      }
    case UPDATE_PROFILE_REQUEST__SUCCESS:
      return {
        ...previousState,
        name: payload.name,
        email: payload.email
      }
    case UPDATE_PASSWORD_REQUEST__SUCCESS:
      return {
        ...previousState,
        password: payload
      }
    case UPDATE_PASSWORD_REQUEST__FAILURE:
      return {
        ...previousState,
        error: payload
      }
    case GET_AGENCY_FROM_ID_REQUEST__SUCCESS:
      return {
        ...previousState,
        error: null,
        company: payload
      }
    case UPDATE_AVATAR:
      return {
        ...previousState,
        avatar: payload
      }
    case LOGOUT:
      return initialState;
    default:
      return previousState;
  }
}
