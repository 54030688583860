import { connect } from 'react-redux';
import Header from '../components/Header';

export default connect(
  ({user, router}) => ({
    loggedIn: (user.id && user.email) ? true : false,
    isNotOnBenchmarksPage: router.location.pathname !== "/benchmarks",
    isNotOnHomePage: router.location.pathname !== "/"
  })
)(Header);
