import React from 'react';

const CLMLogo = ({className}) => (
  <svg width="29.2em" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299 49.6" >
  <circle fill="#56B2E4" cx="41.6" cy="24.4" r="6.2"/>
  <circle fill="#C3549F" cx="41.6" cy="10.4" r="6.2"/>
  <circle fill="#ABD15C" cx="41.6" cy="38.3" r="6.2"/>
  <circle fill="#EC6E23" cx="27.7" cy="24.4" r="6.2"/>
  <circle fill="#ABD15C" cx="27.7" cy="10.4" r="6.2"/>
  <circle fill="#F8DB38" cx="27.7" cy="38.3" r="6.2"/>
  <circle fill="#C3549F" cx="13.8" cy="24.4" r="6.2"/>
  <circle fill="#F8DB38" cx="13.8" cy="10.4" r="6.2"/>
  <circle fill="#56B2E4" cx="13.8" cy="38.3" r="6.2"/>
  <path fill="#364F63" d="M69.3,32.4c-1.7,0.1-3.3-0.7-4.4-1.9c-1.3-1.5-1.9-3.4-1.8-5.4c-0.1-1.9,0.5-3.7,1.7-5.2c1.3-1.4,3.1-2.1,5-2
    c1.4,0.1,2.9,0.4,4.2,1v3.6h-1.1c-0.2,0-0.4-0.1-0.5-0.4c0,0,0,0,0,0c0-0.1-0.1-0.5-0.1-1.1s-0.1-0.9-0.1-0.9
    c-0.4-0.1-0.8-0.2-1-0.2c-0.5-0.1-1-0.1-1.6-0.1c-0.5,0-1.1,0.1-1.5,0.4c-0.5,0.2-1,0.6-1.3,1c-0.8,1.1-1.1,2.5-1.1,3.9
    c-0.1,1.4,0.3,2.8,1.1,4c0.7,0.9,1.8,1.4,3,1.4c1.1,0,2.2-0.4,3.1-1.2c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.4,0.2l0.7,0.9
    C73.3,31.8,71.3,32.6,69.3,32.4z"/>
  <path fill="#364F63" d="M85.9,18.4c0.8,0.3,1.5,0.8,2.1,1.5c0.6,0.7,1.1,1.4,1.3,2.3c0.6,2,0.6,4,0,6c-0.3,0.9-0.7,1.6-1.3,2.3
    c-0.6,0.6-1.3,1.1-2.1,1.5c-1.8,0.7-3.8,0.7-5.6,0c-0.8-0.3-1.5-0.8-2.1-1.5c-0.6-0.7-1.1-1.4-1.4-2.3c-0.3-1-0.5-2-0.4-3
    c0-1,0.1-2,0.4-3c0.3-0.8,0.8-1.6,1.4-2.3c0.6-0.6,1.3-1.1,2.1-1.5C82.2,17.8,84.1,17.8,85.9,18.4z M86.3,29.1
    c0.7-1.2,1.1-2.5,1-3.9c0.1-1.4-0.3-2.8-1-3.9c-0.7-1-1.9-1.5-3.1-1.4c-0.6,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.6-1.3,1.1
    c-0.4,0.5-0.6,1.1-0.8,1.7c-0.2,0.7-0.3,1.5-0.3,2.3c0,0.8,0.1,1.5,0.3,2.2c0.1,0.6,0.4,1.2,0.8,1.7c0.3,0.4,0.8,0.8,1.3,1
    c0.6,0.3,1.2,0.4,1.8,0.4C84.3,30.6,85.5,30.1,86.3,29.1L86.3,29.1z"/>
  <path fill="#364F63" d="M95.8,20.1c0.9-1.3,2.4-2.1,4-2.2c0.9,0,1.7,0.3,2.3,0.8c0.6,0.6,1.1,1.3,1.3,2.1c0.3-0.9,1-1.7,1.8-2.2
    c0.7-0.5,1.6-0.7,2.4-0.7c0.7,0,1.5,0.1,2.2,0.4c0.6,0.2,1.1,0.6,1.5,1c0.9,1.1,1.3,2.6,1.2,4v7c0.9,0.2,1.4,0.3,1.6,0.3
    c0.3,0,0.4,0.2,0.4,0.5v1.2h-4.5v-9c0.1-0.9-0.2-1.8-0.7-2.5c-0.5-0.6-1.3-0.9-2.1-0.9c-0.8,0-1.6,0.3-2.1,0.8
    c-0.6,0.7-1,1.6-0.9,2.5v7c0.2,0,0.7,0.2,1.6,0.3c0.3,0,0.4,0.2,0.4,0.5v1.2h-4.5v-9c0-2.2-0.9-3.3-2.7-3.3
    c-1.3,0.1-2.5,0.8-3.1,1.8v8.5l0.8,0.1c0.5,0.1,0.8,0.2,0.8,0.2c0.3,0,0.4,0.3,0.4,0.5v1.2h-6.5v-1.2c0-0.3,0.1-0.5,0.4-0.5l1.6-0.3
    V20.2c-0.9-0.2-1.4-0.3-1.6-0.3c-0.3,0-0.4-0.3-0.4-0.5v-1.2h3.5c0.3,0,0.6,0.2,0.7,0.5L95.8,20.1L95.8,20.1z"/>
  <path fill="#364F63" d="M120.1,20.3c1.1-1.5,2.9-2.4,4.7-2.5c1.5-0.1,3,0.6,3.9,1.8c1,1.6,1.6,3.5,1.4,5.4c0.1,1.9-0.5,3.7-1.6,5.2
    c-1,1.3-2.6,2.1-4.3,2.1c-1.5,0.1-3-0.5-4.1-1.6V35l0.8,0.2c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0,0.5,0.3,0.4,0.5V37h-6.5v-1.2
    c0-0.3,0.2-0.5,0.4-0.5l1.6-0.3V20.2c-0.9-0.2-1.4-0.3-1.6-0.3c-0.3,0-0.5-0.3-0.4-0.5v-1.2h3.5c0.3,0,0.6,0.2,0.7,0.5L120.1,20.3
    L120.1,20.3z M123.4,30.5c1.2,0.1,2.4-0.4,3.1-1.4c0.8-1.2,1.2-2.6,1.1-4c0-3.5-1.2-5.2-3.6-5.2c-1.5,0-2.9,0.8-3.8,2.1v6.8
    C121,29.9,122.2,30.5,123.4,30.5L123.4,30.5z"/>
  <path fill="#364F63" d="M144,30.5c0.2,0.1,0.4,0.3,0.4,0.5v1.2h-2.9c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.2-0.2-0.3-0.5l-0.3-1.3
    c-0.4,0.3-0.8,0.6-1.1,0.9c-0.3,0.3-0.7,0.5-1.1,0.7c-0.4,0.2-0.8,0.3-1.2,0.4c-0.5,0.1-1,0.2-1.5,0.1c-0.5,0-1-0.1-1.5-0.2
    c-0.5-0.1-0.9-0.4-1.2-0.7c-0.4-0.3-0.6-0.7-0.8-1.2c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.6,0.2-1.1,0.4-1.6c0.4-0.6,0.9-1.1,1.5-1.4
    c0.8-0.5,1.7-0.8,2.7-1c1.3-0.3,2.7-0.4,4.1-0.4v-1.1c0.1-0.9-0.2-1.8-0.7-2.5c-0.5-0.6-1.3-0.9-2.1-0.9c-0.5,0-1,0.1-1.5,0.2
    c-0.4,0.1-0.7,0.3-1.1,0.5c-0.3,0.2-0.6,0.3-0.8,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
    l-0.4-0.8c0.7-0.7,1.6-1.3,2.5-1.7c1-0.4,2-0.6,3-0.6c0.7,0,1.4,0.1,2.1,0.4c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.8,1.1,0.9,1.7
    c0.2,0.7,0.3,1.4,0.3,2.2v7.1C143,30.3,143.8,30.4,144,30.5L144,30.5z M140.1,25.9c-1,0-1.9,0.1-2.9,0.2c-0.7,0.1-1.4,0.3-2,0.6
    c-0.4,0.2-0.8,0.5-1.1,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.3,0.1,0.7,0.2,1c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.4
    c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0,0.8,0,1.2-0.1c0.3-0.1,0.7-0.2,1-0.4c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.5,0.8-0.8L140.1,25.9z"
    />
  <path fill="#364F63" d="M150.4,20.1c1.2-1.4,2.9-2.2,4.7-2.3c1.3-0.1,2.6,0.5,3.5,1.5c0.8,1.1,1.3,2.5,1.2,3.9v6.9
    c0.9,0.2,1.4,0.3,1.6,0.3c0.3,0,0.5,0.3,0.4,0.5v1.2h-4.5v-9c0-2.2-1-3.4-3-3.4c-1.5,0.1-2.9,0.8-3.9,2v8.4c0.1,0,0.6,0.1,1.6,0.3
    c0.3,0,0.4,0.3,0.4,0.5v1.2H146v-1.2c0-0.3,0.1-0.5,0.4-0.5l1.6-0.3V20.2c-0.9-0.2-1.4-0.3-1.6-0.3c-0.3,0-0.4-0.3-0.4-0.5v-1.2h3.5
    c0.3,0,0.6,0.2,0.7,0.5L150.4,20.1L150.4,20.1z"/>
  <path fill="#364F63" d="M169.9,31v1.2h-6.5V31c0-0.3,0.2-0.5,0.4-0.5l1.6-0.3v-10l-1.6-0.3c-0.2-0.1-0.4-0.3-0.4-0.5v-1.2h4.5v12.1
    l1.6,0.3C169.7,30.6,169.9,30.8,169.9,31z M165,14.4c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6
    c0.2-0.2,0.4-0.3,0.6-0.4c0.4-0.2,0.9-0.2,1.3,0c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.5,0.2,0.7
    c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.4,0.2-0.9,0.2-1.3,0C165.6,15.1,165.2,14.8,165,14.4
    L165,14.4z"/>
  <path fill="#364F63" d="M177.7,17.9c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.6,1,1.3,1.2,2c0.3,0.9,0.5,1.8,0.4,2.7
    c0,0.3,0,0.5-0.1,0.8c-0.1,0.1-0.3,0.2-0.5,0.2h-9.4c0,0.8,0.1,1.6,0.3,2.3c0.2,0.6,0.5,1.2,0.9,1.6c0.4,0.4,0.9,0.8,1.4,1
    c0.6,0.2,1.2,0.3,1.8,0.3c0.5,0,1.1-0.1,1.6-0.2c0.4-0.1,0.8-0.3,1.2-0.5c0.3-0.2,0.6-0.3,0.8-0.5c0.2-0.1,0.4-0.2,0.6-0.2
    c0.2,0,0.4,0.1,0.5,0.2l0.7,0.9c-0.3,0.4-0.7,0.7-1.1,1c-0.4,0.3-0.9,0.5-1.4,0.7c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1,0.1-1.5,0.1
    c-0.9,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.6-0.9-2.2-1.5c-0.6-0.7-1.1-1.5-1.4-2.4c-0.3-1-0.5-2.1-0.5-3.2c0-0.9,0.2-1.9,0.5-2.7
    c0.3-0.8,0.7-1.6,1.3-2.2c0.6-0.6,1.3-1.1,2.1-1.5C175.9,18.1,176.8,17.9,177.7,17.9L177.7,17.9z M177.8,19.7c-1-0.1-2.1,0.3-2.8,1
    c-0.7,0.8-1.2,1.8-1.3,2.9h7.7c0-0.5-0.1-1.1-0.2-1.6c-0.1-0.5-0.4-0.9-0.7-1.2c-0.3-0.3-0.7-0.6-1.1-0.8
    C178.8,19.8,178.3,19.7,177.8,19.7L177.8,19.7z"/>
  <path fill="#364F63" d="M190.7,17.9c1.3,0.1,2.5,0.3,3.7,0.8v3.6h-1.1c-0.2,0-0.4-0.1-0.5-0.4c0,0,0,0,0,0c0-0.1-0.1-0.7-0.2-1.8
    c-0.7-0.2-1.4-0.3-2.2-0.3l0,0c-0.7,0-1.4,0.2-1.9,0.6c-0.4,0.3-0.7,0.8-0.8,1.3c0,0.4,0.1,0.8,0.3,1.1c0.4,0.5,1,0.8,1.6,1
    c0.7,0.2,1.3,0.4,1.7,0.5l1.3,0.5c0.4,0.2,0.8,0.4,1.2,0.7c0.8,0.6,1.2,1.5,1.1,2.5c0.1,1.2-0.5,2.4-1.4,3.2
    c-1.1,0.9-2.4,1.4-3.8,1.3c-1.6,0-3.1-0.3-4.6-0.8V28h1c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0.1,0.7,0.2,1.8c0.9,0.4,1.9,0.6,2.9,0.6
    c0.8,0,1.6-0.2,2.2-0.7c0.4-0.3,0.7-0.9,0.7-1.4c0-0.4-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.5-0.8-0.7c-0.4-0.2-0.8-0.3-1.2-0.5
    c-1.2-0.4-2.1-0.7-2.6-0.9c-0.5-0.2-0.9-0.5-1.2-0.8c-0.8-0.6-1.2-1.6-1.1-2.6c0-1.1,0.5-2.1,1.3-2.8
    C187.9,18.3,189.4,17.8,190.7,17.9L190.7,17.9z"/>
  <path fill="#364F63" d="M209.1,31v1.2h-6.5V31c0-0.3,0.2-0.5,0.4-0.5l1.6-0.3V13.7c-0.9-0.2-1.4-0.3-1.6-0.3c-0.3,0-0.5-0.2-0.4-0.5
    v-1.2h4.5v18.5c0.9,0.2,1.4,0.3,1.6,0.3C208.9,30.5,209.1,30.8,209.1,31z"/>
  <path fill="#364F63" d="M217.2,31v1.2h-6.5V31c0-0.3,0.2-0.5,0.4-0.5l1.6-0.3v-10l-1.6-0.3c-0.2-0.1-0.4-0.3-0.4-0.5v-1.2h4.5v12.1
    l1.6,0.3C217,30.6,217.1,30.8,217.2,31z M212.3,14.3c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6
    c0.2-0.2,0.4-0.3,0.6-0.4c0.4-0.2,0.9-0.2,1.3,0c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.5,0.2,0.7
    c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.4,0.2-0.9,0.2-1.3,0
    C212.8,15.1,212.5,14.7,212.3,14.3z"/>
  <path fill="#364F63" d="M232.8,30.5c0.3,0,0.4,0.2,0.4,0.5v1.2h-2.6c-0.4,0-0.7-0.2-1-0.5l-4.6-5.8c-0.1-0.2-0.2-0.3-0.4-0.4
    c-0.2-0.1-0.4-0.1-0.6-0.1h-0.7v4.7c0.1,0,0.6,0.1,1.6,0.3c0.3,0,0.5,0.3,0.5,0.5v1.2h-6.5V31c0-0.3,0.2-0.5,0.4-0.5l1.6-0.3V13.7
    c-0.9-0.2-1.4-0.3-1.6-0.3c-0.3,0-0.4-0.2-0.4-0.5v-1.2h4.5v12.1h0.6c0.3,0,0.7-0.1,0.9-0.4L228,20c-0.1,0-0.2-0.1-0.3-0.1
    s-0.3-0.1-0.5-0.1c-0.3,0-0.5-0.3-0.5-0.5v-1.2h5.9v1.2c0,0.2-0.1,0.4-0.4,0.5c-0.2,0.1-0.8,0.2-1.7,0.3l-3.3,3.5
    c-0.2,0.3-0.5,0.5-0.8,0.8c0.3,0.2,0.6,0.5,0.8,0.9l3.9,5l0.7,0.1C232.2,30.4,232.5,30.5,232.8,30.5L232.8,30.5z"/>
  <path fill="#364F63" d="M240.3,17.9c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.6,1,1.3,1.2,2c0.3,0.9,0.5,1.8,0.5,2.7
    c0,0.3,0,0.5-0.1,0.8c-0.1,0.1-0.3,0.2-0.5,0.2h-9.4c0,0.8,0.1,1.6,0.3,2.3c0.2,0.6,0.5,1.2,1,1.6c0.4,0.4,0.9,0.8,1.4,1
    c0.6,0.2,1.2,0.3,1.8,0.3c0.5,0,1.1-0.1,1.6-0.2c0.4-0.1,0.8-0.3,1.2-0.5c0.3-0.2,0.6-0.3,0.8-0.5c0.2-0.1,0.4-0.2,0.6-0.2
    c0.2,0,0.4,0.1,0.5,0.2l0.7,0.9c-0.3,0.4-0.7,0.7-1.1,1c-0.4,0.3-0.9,0.5-1.4,0.7c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1,0.1-1.5,0.1
    c-0.9,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.6-0.8-2.1-1.5c-0.6-0.7-1.1-1.5-1.4-2.4c-0.3-1-0.5-2.1-0.5-3.2c0-0.9,0.2-1.9,0.5-2.7
    c0.3-0.8,0.7-1.6,1.3-2.2c0.6-0.6,1.3-1.1,2.1-1.5C238.4,18.1,239.4,17.9,240.3,17.9L240.3,17.9z M240.3,19.7c-1-0.1-2.1,0.3-2.8,1
    c-0.7,0.8-1.2,1.8-1.3,2.9h7.7c0-0.5-0.1-1.1-0.2-1.6c-0.1-0.5-0.4-0.9-0.7-1.2c-0.3-0.3-0.7-0.6-1.1-0.8
    C241.4,19.8,240.8,19.7,240.3,19.7L240.3,19.7z"/>
  <path fill="#364F63" d="M258.1,20.1c0.9-1.3,2.4-2.1,4-2.2c0.9,0,1.7,0.3,2.3,0.8c0.6,0.6,1.1,1.3,1.3,2.1c0.3-0.9,1-1.7,1.8-2.2
    c0.7-0.5,1.6-0.7,2.5-0.7c0.7,0,1.5,0.1,2.2,0.4c0.6,0.2,1.1,0.6,1.5,1c0.9,1.1,1.3,2.6,1.2,4v7c0.9,0.2,1.5,0.3,1.6,0.3
    c0.3,0,0.4,0.2,0.4,0.5v1.2h-4.5v-9c0.1-0.9-0.2-1.8-0.7-2.5c-0.5-0.6-1.3-0.9-2.1-0.9c-0.8,0-1.6,0.3-2.1,0.8
    c-0.6,0.7-1,1.6-0.9,2.5v7c0.2,0,0.7,0.2,1.6,0.3c0.3,0,0.4,0.2,0.4,0.5v1.2h-4.5v-9c0-2.2-0.9-3.3-2.7-3.3
    c-1.3,0.1-2.5,0.8-3.1,1.8v8.5l0.8,0.1c0.5,0.1,0.8,0.2,0.8,0.2c0.3,0,0.4,0.3,0.4,0.5v1.2h-6.5v-1.2c0-0.3,0.1-0.5,0.4-0.5l1.6-0.3
    V20.2c-0.9-0.2-1.5-0.3-1.6-0.3c-0.3,0-0.4-0.3-0.4-0.5v-1.2h3.5c0.3,0,0.6,0.2,0.7,0.5L258.1,20.1L258.1,20.1z"/>
  <path fill="#364F63" d="M284.8,17.9c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.6,1,1.3,1.2,2c0.3,0.9,0.5,1.8,0.5,2.7
    c0,0.3,0,0.5-0.1,0.8c-0.1,0.1-0.3,0.2-0.5,0.2h-9.4c0,0.8,0.1,1.6,0.3,2.3c0.2,0.6,0.5,1.2,1,1.6c0.4,0.4,0.9,0.8,1.4,1
    c0.6,0.2,1.2,0.3,1.8,0.3c0.5,0,1.1-0.1,1.6-0.2c0.4-0.1,0.8-0.3,1.2-0.5c0.3-0.2,0.6-0.3,0.8-0.5c0.2-0.1,0.4-0.2,0.6-0.2
    c0.2,0,0.4,0.1,0.5,0.2l0.7,0.9c-0.3,0.4-0.7,0.7-1.1,1c-0.4,0.3-0.9,0.5-1.4,0.7c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1,0.1-1.5,0.1
    c-0.9,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.6-0.8-2.1-1.5c-0.6-0.7-1.1-1.5-1.4-2.4c-0.3-1-0.5-2.1-0.5-3.2c0-0.9,0.2-1.9,0.5-2.7
    c0.3-0.8,0.7-1.6,1.3-2.2c0.6-0.6,1.3-1.1,2.1-1.5C283,18.1,283.9,17.9,284.8,17.9L284.8,17.9z M284.8,19.7c-1-0.1-2.1,0.3-2.8,1
    c-0.7,0.8-1.2,1.8-1.3,2.9h7.7c0-0.5-0.1-1.1-0.2-1.6c-0.1-0.5-0.4-0.9-0.7-1.2c-0.3-0.3-0.7-0.6-1.1-0.8
    C285.9,19.8,285.4,19.7,284.8,19.7L284.8,19.7z"/>
  </svg>
)

export default CLMLogo
