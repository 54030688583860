import { takeEvery, put, call, select, race, take, fork } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import API from '../utils/API';
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST__SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST__SUCCESS,
  UPDATE_PASSWORD_REQUEST__FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST__SUCCESS,
  GET_AGENCY_FROM_ID_REQUEST,
  GET_AGENCY_FROM_ID_REQUEST__SUCCESS,
  GET_AGENCY_FROM_ID_REQUEST__FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_REQUEST__SUCCESS,
  CREATE_USER_REQUEST__FAILURE,
  PASSWORD_RESET_EMAIL_REQUEST,
  AGENCY_ID_EMAIL_REQUEST,
  CONTACT_FORM_REQUEST,
  SET_PASSWORD_REQUEST,
  UPDATE_HIDE_WIZARD_REQUEST,
  ADMIN_IMPERSONATE_REQUEST,
  CROP_AVATAR,
  UPDATE_AVATAR_REQUEST,
  CANCEL_AVATAR_UPDATE,
  updateAvatar,
  logout
} from '../store/user';
import {
  setFormErrors,
  updateFormValue
} from '../store/form';
import {
  openModal
} from '../store/display';
const PLANS = [
  {id: "0", name: "Plan A (PPO - 030350)"},
  {id: "1", name: "Plan B (PPO - 030360)"},
  {id: "2", name: "Plan C (PPO - 030370)"},
  {id: "3", name: "Plan D (PPO - 030380)"}
]
const CLIENTS = [
  {id: "0", name: "ABC Trucking", plans: PLANS},
  {id: "1", name: "BES Company", plans: PLANS},
  {id: "2", name: "Bionic Giant", plans: PLANS},
  {id: "3", name: "CXD Food Co.", plans: PLANS},
  {id: "4", name: "Knicks & Knacks Co.", plans: PLANS},
  {id: "5", name: "Placeholder Company", plans: PLANS}
]

function getFormValues({form}){return form.values}

function getCompanyFromState({user}){return user.company};
function getUserFromState({user}){return user}

export function* ErrorHandler(e){
  if(e.name === 'TokenExpirationException'){
    let { email } = yield select(getUserFromState);
    yield put(logout());
    yield put(setFormErrors({error: "Your session has expired please login again."}));
    yield put(updateFormValue('email', email));
    return true
  }
  return false
}

function getPlanOffset(obj){
  var offset = 0;
  for (var variable in obj) {
    if (obj.hasOwnProperty(variable)) {
      offset += obj[variable].length
    }
  }
  return offset
}
function makeInitialData(response){
  let regions = response.options.geo.regions.map(region => ({value: region.id, label: region.name}));

  let groupOptions = response.options.group_sizes.slice(2);
  let groupSizes = groupOptions.map(size => {
    let label;
    if(size.max_value === size.min_value){
      label = "Max. "+size.min_value;
    } else {
      label = "Min. "+size.min_value;
      if(size.max_value){
        label += " - Max. "+size.max_value;
      }
    }
    return ({value: {max_value: size.max_value, min_value: size.min_value}, label})
  });
  let group = [{value: {max_value: response.options.groupsizemax, min_value: 1}, label: "All"}, {value: {max_value: 9, min_value: 1}, label: "Min. 1 - Max. 9"}, ...groupSizes]


  let dentalproducts = [];
  for (let i = 0; i < response.options.dental_products.length; i++) {
    if(response.options.dental_products[i].name !== "All"){
      dentalproducts.push({value: response.options.dental_products[i].id, label: response.options.dental_products[i].name})
    }
  }
  let medicalproducts = [];
  for (let i = 0; i < response.options.products.length; i++) {
    if(response.options.products[i].name !== "All"){
      medicalproducts.push({value: response.options.products[i].id, label: response.options.products[i].name})
    }
  }
  let major_industry = [];
  for (let i = 0; i < response.options.industries.major_industries.length; i++) {
    major_industry.push({value: response.options.industries.major_industries[i].id, label: response.options.industries.major_industries[i].title, subcategory: "major_industry"});
  }
  let industry = [];
  let industries = response.options.industries.industries.sort((a, b) => a.sic4code - b.sic4code)
  for (let i = 0; i < industries.length; i++) {
    industry.push({value: industries[i].id, label: `${industries[i].sic4code} - ${industries[i].name}`, subcategory: "minor_industry", minor_industries: industries[i].minor_industries.map(minor_industry => ({
      value: minor_industry.id, industry_id: industries[i].id, label: `${minor_industry.sic4code} - ${minor_industry.display_name}`, sic4code: minor_industry.sic4code
    }))});
  }
  let filters = response.options.funds.sort((a, b) => a.rank - b.rank);
  let medicalfilters = [];
  let dentalfilters = [];
  for (let i = 0; i < filters.length; i++) {
    let option = {value: filters[i].id, label: filters[i].name};
    if(filters[i].name === "ASO"){
      option.replace = "FI";
    } else if (filters[i].name === "FI"){
      option.replace = "ASO"
    }
    medicalfilters.push(option);
    if(filters[i].is_dental){
      dentalfilters.push(option)
    }
  }
  let noCustomers = response.options.customers.constructor === Array;
  let uhcmedical = noCustomers ? null : { array: Object.keys(response.options.customers.medical), map: response.options.customers.medical, offset: getPlanOffset(response.options.customers.medical) }
  let uhcdental = noCustomers ? null : { array: Object.keys(response.options.customers.dental), map: response.options.customers.dental, offset: getPlanOffset(response.options.customers.dental) }
  return {
    user: {
      email: response.user.email,
      name: response.user.username,
      company: response.user.user_agency ? response.user.custom_company_name ? response.user.custom_company_name : response.user.user_agency.name : "Placeholder Agency",
      token: response.login.token,
      avatar: response.user.mobile_avatar_url,// ? response.user.mobile_avatar_url.substring(16) : null,
      id: response.user.id,
      ra_num: response.user.ra_num,
      organization_id: response.user.organization_id,
      role_id: response.user.role_id,
      hide_wizard: response.user.hide_wizard,
      error: null
    },
    options: {
      regions,
      group,
      products: {
        medical: medicalproducts,
        dental: dentalproducts
      },
      major_industry,
      industry,
      filter: {
        medical: medicalfilters,
        dental: dentalfilters
      },
      states: response.options.geo.states.map(state => ({value: state.id, label: state.name, abrev: state.abrev, region_id: state.region_id, markets: state.markets.map(market => ({value: market.id, label: market.name, state_id: state.id}))}))
    },
    clients: {
      medical: {
        uhc: uhcmedical,
        added: response.options.companies.filter(company => company.has_medical)
      },
      dental: {
        uhc: uhcdental,
        added: response.options.companies.filter(company => company.has_dental)
      },
      selection: {
        medical: null,
        dental: null
      },
      added: response.options.companies,
      openCategory: null,
      openClient: null,
      filter: ''
    },
    pdf: response.user.api_resources.pdfs,
    data: {medical: {}, dental: {}, isLoading: false, updated_at: response.options.data_date}
  }
}

function* updateAvatarSaga({payload}){
  let { id, avatar } = yield select(getUserFromState);
  try {
    if(payload){
      yield put(updateAvatar(payload))
      let { update, cancel } = yield race({
        update: take(UPDATE_AVATAR_REQUEST),
        cancel: take(CANCEL_AVATAR_UPDATE)
      })

      if(update){

        let response = yield call(API.patch, `admin/users/${id}.json`, {user: {avatar: payload}});
        if(!response.errors){
          yield put(updateAvatar(response.user.avatar))
          let userStorage = sessionStorage.getItem('user');
          userStorage = JSON.parse(userStorage)
          userStorage.avatar = response.user.avatar;
          sessionStorage.setItem('user', JSON.stringify(userStorage));
        } else {
          yield put(updateAvatar(avatar))
          yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Unable to update user."}))
        }
      } else {
        yield put(updateAvatar(avatar))
      }
    }

  } catch (e) {
    yield put(updateAvatar(avatar))
    yield put(setFormErrors({error: "Unable to update user."}))
  }
}

function validateLogin(email, password){
  let errors = {}
  if(!email || !(email.length > 0)){
    errors.email = true;
  }
  if(!password || !(password.length > 0)){
    errors.password= true;
  }
  return Object.keys(errors).length > 0 ? errors : null
}

function* watchForDashboardAndOpenTips(){
  var viewedTipsModal;
  while (!viewedTipsModal) {
    const action = yield take(LOCATION_CHANGE);
    if(action.payload.location.pathname === "/dashboard"){
      yield put(openModal("tips"))
      viewedTipsModal = true;
    }
  }
}
function* loginRequest(email,password){
  let response = yield call(API.post, 'api/login', {os: "iOS", authenticity_token: API.getCSRF(), email, password});
  if(!response.errors){
    let initialData = makeInitialData(response)
    API.setToken(initialData.user.token);
    yield put({ type: LOGIN_REQUEST__SUCCESS, payload: initialData});
    if(initialData.user.hide_wizard){
      yield put(push("/dashboard"))
    } else {
      yield put(push("/benchmarks"))
    }
    if(response.user.first_login){
      yield fork(watchForDashboardAndOpenTips)
    }
    sessionStorage.setItem('data', JSON.stringify(initialData.data));
    sessionStorage.setItem('user', JSON.stringify(initialData.user));
    sessionStorage.setItem('pdf', JSON.stringify(initialData.pdf));
    sessionStorage.setItem('clients', JSON.stringify(initialData.clients));
    sessionStorage.setItem('options', JSON.stringify(initialData.options));
  } else {
    yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Unable to process request. Please try again later."}))
  }
}

function* loginSaga(){
  try {
    let { email, password } = yield select(getFormValues);
    let errors = validateLogin(email, password);
    if(!errors){
      yield call(loginRequest, email, password)
    } else {
      yield put(setFormErrors({error: "Please enter required fields", inputErrors: errors}))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }
}

function validatePasswordUpdateForm(old_password, password, password_confirmation){
  if(!old_password || !(old_password.length > 0)){
    return {error: "Please enter your current password", inputErrors: {old_password: true}}
  } else if (!password || !(password.length > 0) || !password_confirmation || password !== password_confirmation) {
    return {error: "Please enter a matching password pair", inputErrors: {password: true, password_confirmation: true}}
  } else {
    return null
  }
}



function* passwordUpdateSaga(){
  try {
    let { old_password, password, password_confirmation } = yield select(getFormValues);
    let errors = validatePasswordUpdateForm(old_password, password, password_confirmation)
    if(!errors){
      let { id } = yield select(getUserFromState)
      let response = yield call(API.patch, `users/${id}/submitpass.json`, {user: {password, password_confirmation, old_password}});
      if(!response.errors){
        yield put(push("/profile"))
      } else {
        yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Unable to process request. Please try again later."}))
      }
    } else {
      yield put(setFormErrors(errors))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }
}


function* findAgencySaga(){
  try {
    let { id } = yield select(getFormValues);
    if(id && id.length > 0){
      let response = yield call(API.post, 'users/byra.json', {ra_num: id.toUpperCase()});
      if(!response.errors){
        yield put({ type: GET_AGENCY_FROM_ID_REQUEST__SUCCESS, payload: response.organization})
        yield put(push("/register/two"))
      } else {
        yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "UA Agency ID # not recognized"}))
      }
    } else {
      yield put(setFormErrors({error: "Please enter required fields.", inputErrors: {id: true}}))
    }

  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }
}

function validateRegistration(name, email, confirmemail, password, confirmpassword, company){
  if(email && email.length > 0 && confirmemail && confirmemail.length > 0 && email !== confirmemail){
    return {error: "Your email does not match.", inputErrors: {email: true, confirmemail: true}}
  }
  if(password && password.length > 0 && confirmpassword && confirmpassword.length > 0 && password !== confirmpassword){
    return {error: "Your password does not match.", inputErrors: {password: true, confirmpassword: true}}
  }
  let errors = {}
  if(!email || !(email.length > 0)){
    errors.email = true;
  }
  if(!confirmemail || !(confirmemail.length > 0)){
    errors.confirmemail = true;
  }
  if(!password || !(password.length > 0)){
    errors.password = true;
  }
  if(!confirmpassword || !(confirmpassword.length > 0)){
    errors.confirmpassword = true;
  }
  if(!name || !(name.length > 0)){
    errors.name = true;
  }
  if(!company || !(company.length > 0)){
    errors.company = true;
  }
  return Object.keys(errors).length > 0 ? {error: "Please enter required fields", inputErrors: errors} : null
}

function* createUserSaga(){
  try {
    let user = yield select(getUserFromState);
    let { name, email, confirmemail, password, confirmpassword, company } = yield select(getFormValues);
    let errors = validateRegistration(name, email, confirmemail, password, confirmpassword, company)
    if(!errors){
      let userObj = {username: name, email, password, password_confirmation: confirmpassword, role_id: 5, organization_id: user.company.id}
      if(company !== user.company.name){
        userObj.custom_company_name = company
      }
      let createResponse = yield call(API.post, 'admin/users.json', {user: userObj});
      if(createResponse.errors || (createResponse.email && Array.isArray(createResponse.email) && createResponse.email[0] === "has already been taken")){
        if(createResponse.email[0] === "has already been taken"){
          yield put(setFormErrors({error: "A user with that email already exists"}))
        } else {
          yield put(setFormErrors({error: createResponse.errors.length > 0 ? createResponse.errors.join(", ") : "Unable to create user."}))
        }
      } else {
        yield call(loginRequest, email, password)
      }
    } else {
      yield put(setFormErrors(errors))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }

}
function validateUserUpdate(name, email){
  let errors = {}
  if(!name || !(name.length > 0)){
    errors.name = true;
  }
  if(!email || !(email.length > 0)){
    errors.email= true;
  }
  return Object.keys(errors).length > 0 ? errors : null
}
function* updateUserSaga(){
  try {
    let { name, email } = yield select(getFormValues);
    let user = yield select(getUserFromState);
    let updatedUser = {};
    if(user.name !== name){
      updatedUser.full_name = name;
    }
    if(user.email !== email){
      updatedUser.email = email;
    }
    if(Object.keys(updatedUser).length > 0){
      let errors = validateUserUpdate(name, email);
      if(!errors){
        let response = yield call(API.patch, `admin/users/${user.id}.json`, {user: updatedUser});
        if(!response.errors){
          user.name = name;
          user.email = email;
          yield put({ type: UPDATE_PROFILE_REQUEST__SUCCESS, payload: user });
          yield put(push("/dashboard"))
          let userStorage = sessionStorage.getItem('user');
          userStorage = JSON.parse(userStorage)
          userStorage.name = name;
          userStorage.email = email;
          sessionStorage.setItem('user', JSON.stringify(userStorage));
        } else {
          yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Unable to update user"}))
        }
      } else {
        yield put(setFormErrors({error: "Please enter required fields", inputErrors: errors}))
      }

    }


  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }

}

function* resetEmailSaga(){
  try {
    let { email } = yield select(getFormValues);
    if(email && email.length > 0){
      let response = yield call(API.post, `users/send_forgot_email.json`, {email});
      if(!response.errors){
        yield put(push({pathname: "/success", state: {referrer: "recovery"}}))
      } else {
        yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Email not recognized"}))
      }
    } else {
      yield put(setFormErrors({error: "You must enter your email", inputErrors: {email: true}}))
    }

  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }

}
function validateAgencyIDEmailForm(name, email, agency, address1, city, state, zip, phone, recaptcha){
  let errors = {}
  if(!name || !(name.length > 0)){
    errors.name = true;
  }
  if(!email || !(email.length > 0)){
    errors.email= true;
  }
  if(!agency || !(agency.length > 0)){
    errors.agency = true;
  }
  if(!address1 || !(address1.length > 0)){
    errors.address1= true;
  }
  if(!city || !(city.length > 0)){
    errors.city = true;
  }
  if(!state || !(state.length > 0)){
    errors.state= true;
  }
  if(!zip || !(zip.length > 0)){
    errors.zip = true;
  }
  if(!phone || !(phone.length > 0)){
    errors.phone= true;
  }
  if(!recaptcha){
    errors.recaptcha= true;
  }
  return Object.keys(errors).length > 0 ? errors : null
}
function* getAgencyIdEmailSaga(){
  try {
    let { name, email, agency, address1, address2, city, state, zip, phone, recaptcha } = yield select(getFormValues);
    let errors = validateAgencyIDEmailForm(name, email, agency, address1, city, state, zip, phone, recaptcha);
    if(!errors){
      let response = yield call(API.post, `users/emailus.json`, {get_account_num: true, name, email, agency_name: agency, address: address1, city, state, zipcode: zip, phone, "g-recaptcha-response": recaptcha});
      if(!response.errors){
        yield put(push("/success"))
        yield put(push({pathname: "/success", state: {referrer: "agency-id"}}))
      } else {
        yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Unable to process request. Please try again later."}))
      }
    } else {
      yield put(setFormErrors({error: "Please enter required fields", inputErrors: errors}))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }
}


function validateContactForm(name, email, type, message, recaptcha){
  let errors = {}
  if(!name || !(name.length > 0)){
    errors.name = true;
  }
  if(!email || !(email.length > 0)){
    errors.email= true;
  }
  if(!type || !(type.length > 0)){
    errors.type = true;
  }
  if(!message || !(message.length > 0)){
    errors.message= true;
  }
  if(!recaptcha){
    errors.recaptcha= true;
  }
  return Object.keys(errors).length > 0 ? errors : null
}
function* contactFormSaga(){
  try {
    let { name, email, type, message, recaptcha } = yield select(getFormValues);
    let errors = validateContactForm(name, email, type, message, recaptcha);
    if(!errors){
      let response = yield call(API.post, `users/emailus.json`, {contact_us: true, name, email, type, message, "g-recaptcha-response": recaptcha});
      if(!response.errors){
        yield put(push({pathname: "/success", state: {referrer: "contact"}}))
      } else {
        yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Unable to process request. Please try again later."}))
      }
    } else {
      yield put(setFormErrors({error: "Please enter required fields", inputErrors: errors}))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }
}

function* setPasswordSaga({payload}){
  try {
    let { password, password_confirmation } = yield select(getFormValues);
    if(password && password.length > 0 && password_confirmation && password === password_confirmation){
      let response = yield call(API.get, `users/setpass.json?verif=${payload}`);
      if(!response.errors && response.user){
        let submitResponse = yield call(API.patch, `users/${response.user.id}/submitpass.json`, {user: {password, password_confirmation}, verif: payload});
        if(!submitResponse.errors){
          yield put(push({pathname: "/success", state: {referrer: "set-password"}}))
        } else {
          yield put(setFormErrors({error: response.errors.length > 0 ? response.errors.join(", ") : "Unable to process request"}))
        }
      } else {
        yield put(setFormErrors({error: "Unable to process request"}))
      }

    } else {
      yield put(setFormErrors({error: "Please enter a matching password pair", inputErrors: {password: true, password_confirmation: true}}))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put(setFormErrors({error: "Unable to process request. Please try again later."}))
    }
  }
}

function* hideWizardSettingSaga({payload}){
  try {
    let user = yield select(getUserFromState);
    let response = yield call(API.patch, `admin/users/${user.id}.json`, {user: {hide_wizard: payload}});
    if(!response.errors){
      user.hide_wizard = payload;
      yield put({ type: UPDATE_PROFILE_REQUEST__SUCCESS, payload: user });
      let userStorage = sessionStorage.getItem('user');
      userStorage = JSON.parse(userStorage)
      userStorage.hide_wizard = payload;
      sessionStorage.setItem('user', JSON.stringify(userStorage));
    }
  } catch (e) {
    yield call(ErrorHandler, e)
  }
}

function* impersonateSaga({payload}){
  try {
    let response = yield call(API.post, 'api/login', {os: "iOS", authenticity_token: API.getCSRF(), switch: payload});
    if(!response.errors){
      let initialData = makeInitialData(response)
      API.setToken(initialData.user.token);
      yield put({ type: LOGIN_REQUEST__SUCCESS, payload: initialData});
      if(initialData.user.hide_wizard){
        yield put(push("/dashboard"))
      } else {
        yield put(push("/benchmarks"))
      }
      sessionStorage.setItem('data', JSON.stringify(initialData.data));
      sessionStorage.setItem('user', JSON.stringify(initialData.user));
      sessionStorage.setItem('pdf', JSON.stringify(initialData.pdf));
      sessionStorage.setItem('clients', JSON.stringify(initialData.clients));
      sessionStorage.setItem('options', JSON.stringify(initialData.options));
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      alert("Unable to impersonate user")
      yield put(push('/'))
    }
  }
}

export default function* userSagas() {
  yield takeEvery(LOGIN_REQUEST, loginSaga);
  yield takeEvery(UPDATE_PASSWORD_REQUEST, passwordUpdateSaga);
  yield takeEvery(GET_AGENCY_FROM_ID_REQUEST, findAgencySaga);
  yield takeEvery(CREATE_USER_REQUEST, createUserSaga);
  yield takeEvery(UPDATE_PROFILE_REQUEST, updateUserSaga);
  yield takeEvery(PASSWORD_RESET_EMAIL_REQUEST, resetEmailSaga);
  yield takeEvery(AGENCY_ID_EMAIL_REQUEST, getAgencyIdEmailSaga);
  yield takeEvery(CONTACT_FORM_REQUEST, contactFormSaga);
  yield takeEvery(SET_PASSWORD_REQUEST, setPasswordSaga);
  yield takeEvery(UPDATE_HIDE_WIZARD_REQUEST, hideWizardSettingSaga);
  yield takeEvery(ADMIN_IMPERSONATE_REQUEST, impersonateSaga);
  yield takeEvery(CROP_AVATAR, updateAvatarSaga);
}
