import React from 'react';
import { connect } from 'react-redux';
import UserMenu from '../components/UserMenu';
import { logout } from '../store/user'
import { openModal } from '../store/display'

class UserMenuContainer extends React.Component {
  constructor(){
    super();
    this.state = {
      isOpen: false
    }
    this.menuContainer = null;
    this.setMenuRef = (e) => {
      this.menuContainer = e;
    }
  }
  componentDidMount(){
    this.startInactivityWatcher()
  }
  startInactivityWatcher = () => {
    this.timer = setInterval(this.incrementTimer, 60000)
    document.addEventListener("mousemove", this.resetTimer)
    document.addEventListener("keypress", this.resetTimer)
  }
  resetTimer = () => {
    this.idleTime = 0;
  }
  incrementTimer = () => {
    this.idleTime++;
    if(this.idleTime === 30){
      this.props.openModal("inactive-warning")
    } else if(this.idleTime >= 60){
      this.props.openModal("inactive-logout")
      this.props.logout();

    }
  }
  componentDidUpdate(){
    if(this.state.isOpen){
      document.addEventListener('click', this.handleClickOutside);
      document.addEventListener('touchstart', this.handleClickOutside);
    } else {
      document.removeEventListener('click', this.handleClickOutside);
      document.removeEventListener('touchstart', this.handleClickOutside);
    }
  }
  componentWillUnmount(){
    if(this.state.isOpen){
      document.removeEventListener('click', this.handleClickOutside);
      document.removeEventListener('touchstart', this.handleClickOutside);
    }
    document.removeEventListener('mousemove', this.resetTimer)
    document.removeEventListener('keypress', this.resetTimer)
    clearInterval(this.timer);
  }
  toggleOpen = () => this.setState((prevState) => ({isOpen: !prevState.isOpen}))
  handleClickOutside = (event) => {
    if (
      this.menuContainer
      && !this.menuContainer.contains(event.target)
    ) {
      this.toggleOpen();
    }
  }
  render(){
    return <UserMenu name={this.props.name} isOpen={this.state.isOpen} toggleOpen={this.toggleOpen} menuRef={this.setMenuRef} admin={this.props.admin}/>
  }
}

export default connect(
  ({user}) => ({name: user.name, admin: user.role_id < 4}),
  { openModal, logout }
)(UserMenuContainer);
