import React from 'react';
import cssVars from 'css-vars-ponyfill';
import styles from './index.module.css'

const ThemeSwitch = ({isLight, toggleTheme}) =>
  <div onClick={toggleTheme} className={styles.container}>
    <div className={isLight ? styles.switch+" "+styles.active : styles.switch}>
      Light
      &nbsp;
    </div>
    <div>&nbsp;/&nbsp;</div>
    <div className={!isLight ? styles.switch+" "+styles.active : styles.switch}>
      &nbsp;
      Dark
    </div>
  </div>

export default ThemeSwitch;
