import { LOGIN_REQUEST__SUCCESS } from './user';
import { SET_INSURANCE_TYPE } from './display';

export const SET_OPEN_CATEGORY_DROPDOWN = "SET_OPEN_CATEGORY_DROPDOWN";
export const SET_OPEN_CLIENT_DROPDOWN = "SET_OPEN_CLIENT_DROPDOWN";
export const SET_PLAN = "SET_PLAN";
export const REMOVE_PLAN = "REMOVE_PLAN";
export const SET_CLIENT_FILTER = "SET_CLIENT_FILTER";
export const ADD_CLIENT = "ADD_CLIENT";
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_REQUEST__SUCCESS = "DELETE_COMPANY_REQUEST__SUCCESS";
export const DELETE_COMPANY_REQUEST__FAILURE = "DELETE_COMPANY_REQUEST__FAILURE";
export const ADD_CLIENT_TO_LIST = "ADD_CLIENT_TO_LIST";
export const REMOVE_CLIENT_FROM_LIST = "REMOVE_CLIENT_FROM_LIST";

export const setCategoryDropdown = (category, value) => ({type: SET_OPEN_CATEGORY_DROPDOWN, payload: {category, value}});
export const setPlan = (insurance, client, plan) => ({type: SET_PLAN, payload: {insurance, client, plan}});
export const removePlan = (insurance, client, plan) => ({type: REMOVE_PLAN, payload: {insurance, client, plan}});
export const openClientDropdown = (client, insurance, plans) => ({type: SET_OPEN_CLIENT_DROPDOWN, payload: {client, insurance, plans}});
export const setClientFilter = (insurance, filter) => ({type: SET_CLIENT_FILTER, payload: {insurance,filter}});
export const addClient = (client, isMedical, isDental) => ({type: ADD_CLIENT, payload: {client, isMedical, isDental}});
export const deleteCompany = () => ({type: DELETE_COMPANY_REQUEST})
export const addClientToList = (insurance, client) => ({type: ADD_CLIENT_TO_LIST, payload: {insurance, client}})
export const removeClientFromList = (insurance, id) => ({type: REMOVE_CLIENT_FROM_LIST, payload: {insurance, id}})

const initialState = {
  medical: {
    uhc: {
      array: [],
      map: {}
    },
    added: []
  },
  dental: {
    uhc: {
      array: [],
      map: {}
    },
    added: []
  },
  added: [],
  selection: {
    medical: null,
    dental: null
  },
  addedOpen: false,
  uhcOpen: false,
  openClient: null,
  filter: '',
  clientSelected: false
};

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case SET_OPEN_CATEGORY_DROPDOWN:
      return {
        ...previousState,
        [payload.category]: payload.value
      }
    case SET_OPEN_CLIENT_DROPDOWN:
      if(payload.client){
        return {
          ...previousState,
          openClient: payload.client,
          selection: {
            ...previousState.selection,
            [payload.insurance]: {client: payload.client, plans: payload.plans}
          },
          clientSelected: true
        }
      } else {
        return {
          ...previousState,
          openClient: null,
          selection: {
            ...previousState.selection,
            [payload.insurance]: null
          },
          clientSelected: false
        }
      }

    case SET_PLAN:
      let selection;
      if(previousState.selection[payload.insurance] && previousState.selection[payload.insurance].client === payload.client && !previousState.clientSelected){
        selection = {client: payload.client, plans: [...previousState.selection[payload.insurance].plans, payload.plan]}
      } else {
        selection = {client: payload.client, plans: [payload.plan]};
      }
      return {
        ...previousState,
        selection: {
          ...previousState.selection,
          [payload.insurance]: selection
        },
        clientSelected: false
      }
    case LOGIN_REQUEST__SUCCESS:
      return {
        ...previousState,
        ...payload.clients
      }
    case SET_CLIENT_FILTER:
      return {
        ...previousState,
        filter: payload.filter,
        addedOpen: true,
        uhcOpen: true
      }
    case ADD_CLIENT:
      return {
        ...previousState,
        medical: {
          ...previousState.medical,
          added: payload.isMedical ? [...previousState.medical.added, payload.client] : previousState.medical.added
        },
        dental: {
          ...previousState.dental,
          added: payload.isDental ? [...previousState.dental.added, payload.client] : previousState.dental.added
        },
        added: [...previousState.added, payload.client]
      }
    case ADD_CLIENT_TO_LIST:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          added: [...previousState[payload.insurance].added, payload.client]
        }
      }
    case REMOVE_CLIENT_FROM_LIST:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          added: previousState[payload.insurance].added.filter(client => client.id !== payload.id)
        }
      }
    case REMOVE_PLAN:
      let clientSelection;
      if(payload.plan && previousState.selection[payload.insurance] && previousState.selection[payload.insurance]){
        if(previousState.selection[payload.insurance].plans.length > 1){
          clientSelection = {
            client: previousState.selection[payload.insurance].client,
            plans: previousState.selection[payload.insurance].plans.filter(plan => plan !== payload.plan)
          }
        } else {
          clientSelection = null;
        }
      } else {
        clientSelection = null;
      }
      return {
        ...previousState,
        selection: {
          ...previousState.selection,
          [payload.insurance]: clientSelection
        }
      }
    case DELETE_COMPANY_REQUEST__SUCCESS:
      return {
        ...previousState,
        medical: {
          ...previousState.medical,
          added: previousState.medical.added.filter(client => client.id !== payload)
        },
        dental: {
          ...previousState.dental,
          added: previousState.dental.added.filter(client => client.id !== payload)
        },
        added: previousState.added.filter(client => client.id !== payload)
      }
    case SET_INSURANCE_TYPE:
      const sel = previousState.selection[payload]
      return {
        ...previousState,
        openClient: sel ? sel.client : null,
        clientSelected: sel ? true : false
      }
    case "LOGOUT":
      return initialState;
    default:
      return previousState;
  }
}
