import { SET_CLIENT_FILTER } from './clients';
import { LOGIN_REQUEST__SUCCESS } from './user';

export const CLICK_ADMIN_FILTER_REQUEST_BUTTON = "CLICK_ADMIN_FILTER_REQUEST_BUTTON";
export const GET_MORE_ADMIN_PLANS_REQUEST__SUCCESS = "GET_MORE_ADMIN_PLANS_REQUEST__SUCCESS";
export const GET_MORE_ADMIN_PLANS_REQUEST = "GET_MORE_ADMIN_PLANS_REQUEST";
export const FILTERED_ADMIN_PLANS_REQUEST = "FILTERED_ADMIN_PLANS_REQUEST";
export const FILTERED_ADMIN_PLANS_REQUEST__SUCCESS = "FILTERED_ADMIN_PLANS_REQUEST__SUCCESS";
export const SET_PLAN_ERROR = "SET_PLAN_ERROR";

export const getMoreAdminPlans = (insurance) => ({type: GET_MORE_ADMIN_PLANS_REQUEST, payload: {insurance}})
export const clickAdminFilterRequestButton = (insurance) => ({type: CLICK_ADMIN_FILTER_REQUEST_BUTTON, payload: {insurance}})
export const planError = (payload) => ({type: SET_PLAN_ERROR, payload})

const initialState = {
  medical: {
    array: [],
    map: {},
    limit: 100,
    offset: 0,
    count: 0,
    hasCount: false,
    error: false,
    isLoading: true,
    loadingNext: false
  },
  dental: {
    array: [],
    map: {},
    limit: 100,
    offset: 0,
    count: 0,
    hasCount: false,
    error: false,
    isLoading: true,
    loadingNext: false
  }
};

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case CLICK_ADMIN_FILTER_REQUEST_BUTTON:
    case SET_CLIENT_FILTER:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          isLoading: true,
          loadingNext: false,
          error: false
        }
      }
    case GET_MORE_ADMIN_PLANS_REQUEST:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          isLoading: false,
          loadingNext: true,
          error: false
        }
      }
      /*
    case LOGIN_REQUEST__SUCCESS:
      return {
        ...previousState,
        medical: {
          ...previousState.medical,
          ...payload.clients.medical.uhc,
          limit: 100
        },
        dental: {
          ...previousState.dental,
          ...payload.clients.dental.uhc,
          limit: 100
        },
        isLoading: false
      }
      */
    case FILTERED_ADMIN_PLANS_REQUEST__SUCCESS:
    case GET_MORE_ADMIN_PLANS_REQUEST__SUCCESS:
      return {
        ...previousState,
        ...payload
      }
    case SET_PLAN_ERROR:
      return {
        ...previousState,
        [payload]: {
          ...previousState[payload],
          error: true,
          isLoading: false,
          loadingNext: false
        }

      }
    default:
      return previousState;
  }
}
