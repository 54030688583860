import React from 'react';
import Panel from '../Panel';
import Heading from '../Heading';
import Button from '../Button';
import styles from './index.module.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log("ERROR", error);
    console.log("INFO", info);
  }
  reload = () => this.setState({hasError: false}, () => {
    window.location.reload();
  })
  render() {
    if (this.state.hasError) {
      return(
        <div className={styles.container}>
          <Panel className={styles.content}>
            <Heading>Something went wrong</Heading>
            <div className={styles.buttons}>
            <Button color="secondary" onClick={this.reload} size="s">RETURN</Button>
            <Button to="/" size="s">HOME</Button>
            </div>

          </Panel>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
