import { takeEvery, put, call, select, race, take } from 'redux-saga/effects';
import API from '../utils/API';
import { mapComparisonStateFromResponse } from '../utils/data';
import {
  SAVE_PDF,
  SAVE_PDF_REQUEST,
  SAVE_PDF_REQUEST__SUCCESS,
  SAVE_PDF_REQUEST__FAILURE,
  REMOVE_PDF_REQUEST,
  REMOVE_PDF_REQUEST__SUCCESS,
  REMOVE_PDF_REQUEST__FAILURE
} from '../store/pdf';
import {
  openModal,
  CLOSE_MODAL,
  CONFIRM_ACTION
} from '../store/display';
import {
  addError
} from '../store/errors';
import {
  ErrorHandler
} from './user';

const getUserQueryId = ({data, display}) => data[display.insurance].user_query_id;

function* savePDFSaga(){
  try {
    let action = yield take(SAVE_PDF_REQUEST)
    let user_query_id = yield select(getUserQueryId);
    if(user_query_id){
      let response = yield call(API.post, `reports/${user_query_id}/pdf.json`, action.payload);
      let list = response.user_pdfs.map(collection => ({...collection.pdf, user_report_id: collection.id}))
      yield put({type: SAVE_PDF_REQUEST__SUCCESS, payload: {list, current: {detailed: {preview: response.previews.detailed.url, download: response.user_pdfs[0].detailed.url}, summary:{preview: response.previews.summary.url, download: response.user_pdfs[0].summary.url}}}})
      yield put(openModal("report_menu"))
      sessionStorage.setItem('pdf', JSON.stringify(list))
    } else {
      yield put({type: SAVE_PDF_REQUEST__FAILURE})
      yield put(addError("You must run a query first"))
    }

  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put({type: SAVE_PDF_REQUEST__FAILURE})
      yield put(addError("Error saving PDF."))
    }
  }
}

function* archivePDFSaga({payload}){
  try {
    yield put(openModal('confirmation'))
    const {confirm, cancel} = yield race({
      confirm: take(CONFIRM_ACTION),
      cancel: take(CLOSE_MODAL)
    })
    if(confirm){
      let { user_pdfs } = yield call(API.post, `reports/${payload.user_report_id}/archive`);
      let list = user_pdfs.map(collection => ({...collection.pdf, user_report_id: collection.id}))
      yield put({type: REMOVE_PDF_REQUEST__SUCCESS, payload: list})
      sessionStorage.setItem('pdf', JSON.stringify(list))
    }
  } catch (e) {
    let handled = yield call(ErrorHandler, e)
    if(!handled){
      yield put({type: REMOVE_PDF_REQUEST__FAILURE, payload})
      yield put(addError("Unable to process request"))
    }
  }
}

export default function* pdfSagas() {
  yield takeEvery(SAVE_PDF, savePDFSaga);
  yield takeEvery(REMOVE_PDF_REQUEST, archivePDFSaga);
}
