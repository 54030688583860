import React from 'react';
import { Route } from 'react-router-dom';
import UserBanner from '../../containers/UserBanner';
import PrivateFooter from '../PrivateFooter';

import styles from './index.module.css';

const PrivateLayout = ({children, noFooter}) =>
  <>
    <Route path="/(dashboard|comparison|archive)/" render={() =>
      <div className={styles.banner}>
        <UserBanner />
      </div>
    } />
    <main>
    {children}
    </main>
    {!noFooter && <PrivateFooter />}
  </>

export default PrivateLayout;
