import { CLICK_ADMIN_FILTER_REQUEST_BUTTON } from './adminPlans';

export const ADD_BENCHMARK_VALUE = "CLIENT_FILTER_ADD_BENCHMARK_VALUE";
export const ADD_ALL_SELECTIONS = "CLIENT_FILTER_ADD_ALL_SELECTIONS";
export const REMOVE_BENCHMARK = "CLIENT_FILTER_REMOVE_BENCHMARK";
export const ADD_MULTIPLE_STATES = "CLIENT_FILTER_ADD_MULTIPLE_STATES";
export const SET_STATES = "CLIENT_FILTER_SET_STATES";
export const CLEAR_SELECTIONS = "CLIENT_FILTER_CLEAR_SELECTIONS";
export const ADD_STATE = "CLIENT_FILTER_ADD_STATE";
export const ADD_MARKET = "CLIENT_FILTER_ADD_MARKET";
export const RESET_FILTER_SELECTIONS = "RESET_FILTER_SELECTIONS";

export const addBenchmarkValue = (insurance, category, multi, option) => ({ type: ADD_BENCHMARK_VALUE, payload: {insurance, category, multi, option}});
export const removeBenchmark = (insurance, category, option) => ({type: REMOVE_BENCHMARK, payload: {insurance, category, option}})
export const addAllSelections = (insurance, category) => ({type: ADD_ALL_SELECTIONS, payload: {insurance, category}})
export const clearSelections = (insurance, category) => ({ type: CLEAR_SELECTIONS, payload: {insurance, category} })
export const addStates = (insurance, states) => ({type: ADD_MULTIPLE_STATES, payload: {insurance, states}})
export const setStates = (insurance, states) => ({type: SET_STATES, payload: {insurance, states}})
export const addState = (insurance, state) => ({type: ADD_STATE, payload: {insurance, state}})
export const addMarket = (insurance, state, market) => ({type: ADD_MARKET, payload: {insurance, state, market}})
export const resetFilterSelections = () => ({type: RESET_FILTER_SELECTIONS})

const initialState = {
  medical: {
    regions: [],
    markets: [],
    setRegions: [],
    setMarkets: []
  },
  dental: {
    regions: [],
    markets: [],
    setRegions: [],
    setMarkets: []
  }
};

function makeNewCategoryArray(option, previousArray, multi){
  let newArray;
  if(option.replace){
    let idx = previousArray.findIndex(filter => filter.label === option.replace);
    if(idx !== -1){
      newArray = [...previousArray.slice(0, idx), ...previousArray.slice(idx+1), option]
    } else {
      newArray = [...previousArray, option]
    }
  } else if(multi && previousArray[0] && previousArray[0].label !== "All") {
    newArray = [...previousArray, option]
  } else {
    newArray = [option]
  }
  return newArray;
}

function merge(a1, a2) {
   var hash = {};
   var arr = [];
   for (var i = 0; i < a1.length; i++) {
      if (hash[a1[i].value] !== true) {
        hash[a1[i].value] = true;
        arr[arr.length] = a1[i];
      }
   }
   for (var i = 0; i < a2.length; i++) {
      if (hash[a2[i].value] !== true) {
        hash[a2[i].value] = true;
        arr[arr.length] = a2[i];
      }
   }
   return arr;
}

function removeMarketsOfStates(markets, states){
  var marketsOfAddedStates = []
  var selectedMarkets = [markets]
  for (var i = 0; i < states.length; i++) {
    if(states[i].markets && states[i].markets){
      marketsOfAddedStates.concat(states[i].markets)
    }
  }
  var idxs = []
  for (var i = 0; i < marketsOfAddedStates.length; i++) {
    var idx = selectedMarkets.findIndex(market => market === marketsOfAddedStates[i])
    if(idx > -1){
      idxs.push(idx)
    }
  }
  for (var i = idxs.length; i >= 0; i--) {
    selectedMarkets.splice(idxs[i], 1);
  }
  return selectedMarkets;
}

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case ADD_BENCHMARK_VALUE:
      let oldCategory = previousState[payload.insurance][payload.category];
      let newCategory = makeNewCategoryArray(payload.option, oldCategory, payload.multi);
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          [payload.category]: newCategory
        }
      }
    case ADD_ALL_SELECTIONS:
    return {
      ...previousState,
      [payload.insurance]: {
        ...previousState[payload.insurance],
        [payload.category]: [{value: "all", label: "All"}]
      }
    }
    case CLEAR_SELECTIONS:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          [payload.category]: []
        }
      }
    case REMOVE_BENCHMARK:
      let idx = previousState[payload.insurance][payload.category].findIndex(benchmark => benchmark === payload.option);
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          [payload.category]: [...previousState[payload.insurance][payload.category].slice(0, idx), ...previousState[payload.insurance][payload.category].slice(idx+1)]
        }
      }
    case ADD_MULTIPLE_STATES:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: merge(previousState[payload.insurance].regions, payload.states),
          markets: removeMarketsOfStates(previousState[payload.insurance].markets, payload.states)
        }
      }
    case SET_STATES:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: payload.states
        }
      }
    case ADD_STATE:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: [...previousState[payload.insurance].regions, payload.state],
          markets: previousState[payload.insurance].markets.filter(market => market.state_id !== payload.state.value)
        }
      }
    case ADD_MARKET:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          regions: previousState[payload.insurance].regions.filter(region => region.value !== payload.market.state_id),
          markets: [...previousState[payload.insurance].markets, payload.market]
        }
      }

    case CLICK_ADMIN_FILTER_REQUEST_BUTTON:
      return {
        medical: {
          ...previousState.medical,
          setRegions: [...previousState.medical.regions],
          setMarkets: [...previousState.medical.markets]
        },
        dental: {
          ...previousState.dental,
          setRegions: [...previousState.dental.regions],
          setMarkets: [...previousState.dental.markets]
        }
      }
    case RESET_FILTER_SELECTIONS: {
      return {
        medical: {
          ...previousState.medical,
          regions: [...previousState.medical.setRegions],
          markets: [...previousState.medical.setMarkets]
        },
        dental: {
          ...previousState.dental,
          regions: [...previousState.dental.setRegions],
          markets: [...previousState.dental.setMarkets]
        }
      }
    }
    case "LOGIN_REQUEST__SUCCESS":
      return initialState;
      break;
    case "LOGOUT":
      return initialState;
      break;
    default:
      return previousState;
  }
}
