import React from 'react';
import { Link } from 'react-router-dom';
import UHCLogo from '../SVGComponents/UHCLogo';
import UpdatedDate from '../../containers/UpdatedDate';
import styles from './index.module.css';

const PrivateFooter = () =>
  <footer className={styles.container}>
    <div className={styles.row}>
      <div className={styles.disclaimer}>
        By using Companies Like Me&trade; you agree to our <Link className={styles.link} to="/tos">Terms of use</Link> and <Link className={styles.link} to="/privacy">Privacy Policy</Link>. This is a private data tool containing proprietary and confidential information. Authorized users may use Companies Like Me&trade; for business purposes only. Any authorized or inappropriate access, use or distribution of information contained within Companies Like Me&trade; is strictly prohibited. Companies Like Me&trade; is used by permission from UnitedHealth Group.
      </div>
      <UHCLogo className={styles.logo} />
    </div>
    <div className={styles.row}>
      <div className={styles.copy}>
        &copy; 2019 UnitedHealthCare Services. <Link className={styles.link} to="/contact">Contact Us</Link>
      </div>
      <UpdatedDate />
    </div>
  </footer>

export default PrivateFooter;
