export const CHANGE_NETWORK_TYPE = "CHANGE_NETWORK_TYPE";
export const SET_INSURANCE_TYPE = "SET_INSURANCE_TYPE";
export const CHANGE_STAT_TYPE = "CHANGE_STAT_TYPE";
export const TOGGLE_COMPARISON = "TOGGLE_COMPARISON";
export const OPEN_MARKET_MODAL = "OPEN_MARKET_MODAL";
export const CLOSE_MARKET_MODAL = "CLOSE_MARKET_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CONFIRM_ACTION = "CONFIRM_ACTION";
export const TOGGLE_THEME = "TOGGLE_THEME";
export const TOGGLE_TOP_PLANS_DISPLAY = "TOGGLE_TOP_PLANS_DISPLAY";

export const openModal = payload => ({ type: OPEN_MODAL, payload })
export const closeModal = () => ({type: CLOSE_MODAL})
export const confirm = () => ({type: CONFIRM_ACTION})
export const setInsuranceType = (payload) => ({ type: SET_INSURANCE_TYPE, payload});
export const changeNetworkType = (payload) => ({ type: CHANGE_NETWORK_TYPE, payload});
export const changeStatType = (payload) => ({ type: CHANGE_STAT_TYPE, payload });
export const toggleComparison = () => ({ type: TOGGLE_COMPARISON })
export const openMarketModal = (state) => ({type: OPEN_MARKET_MODAL, payload: state})
export const closeMarketModal = () => ({type: CLOSE_MARKET_MODAL})
export const toggleTheme = () => ({type: TOGGLE_THEME})
export const toggleTopPlansDisplay = () => ({type: TOGGLE_TOP_PLANS_DISPLAY})

const initialState = { modal: null, insurance: "medical", network: "in", stat: "average", showComparison: true, marketModal: null, theme: 'light', hideTopPlansOnReport: false };

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case CHANGE_NETWORK_TYPE:
      return {
        ...previousState,
        network: payload
      }
    case SET_INSURANCE_TYPE:
      return {
        ...previousState,
        insurance: payload
      }
    case CHANGE_STAT_TYPE:
      return {
        ...previousState,
        stat: payload
      }
    case TOGGLE_COMPARISON:
      return {
        ...previousState,
        showComparison: !previousState.showComparison
      }
    case OPEN_MARKET_MODAL:
      return {
        ...previousState,
        marketModal: payload
      }
    case CLOSE_MARKET_MODAL:
      return {
        ...previousState,
        marketModal: null
      }
    case OPEN_MODAL:
        return {
          ...previousState,
          modal: payload
        }
    case CLOSE_MODAL:
      return {
        ...previousState,
        modal: null
      }
    case CONFIRM_ACTION:
      return {
        ...previousState,
        modal: null
      }
    case TOGGLE_THEME:
      return {
        ...previousState,
        theme: previousState.theme === "light" ? "dark" : "light"
      }
    case TOGGLE_TOP_PLANS_DISPLAY:
      return {
        ...previousState,
        hideTopPlansOnReport: !previousState.hideTopPlansOnReport
      }
    default:
      return previousState;
  }
}
