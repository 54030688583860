import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

import { loadUser } from '../store/user';

const PublicRoute = ({userState, loadUser, component: Component, ...rest}) =>
  <Route {...rest} render={matchProps => {
    if(!userState){
      return(
        <Component {...matchProps} />
      )
    }

    return(
      <Redirect to="/dashboard" />
    )
  }} />

export default connect(
  ({user}) => ({userState: user && user.email}),
  {loadUser}
)(PublicRoute);
