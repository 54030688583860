import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import CLMLogo from '../SVGComponents/CLMLogo';
import Button from '../Button';
import UserMenu from '../../containers/UserMenu';
import ThemeSwitch from '../../containers/ThemeSwitch';
import ComparisonToggle from '../../containers/ComparisonToggle';
import styles from './index.module.css';

const Header = ({loggedIn, isNotOnBenchmarksPage, isNotOnHomePage}) =>
  <header>
    <Link to={loggedIn ? "/dashboard" : "/"}>
      <CLMLogo className="logo" />
    </Link>
    {loggedIn ? (
      <div className={styles.private}>
        <div className={styles.links}>
          <NavLink to="/dashboard" activeClassName={styles.activeLink}>Dashboard</NavLink>
          {isNotOnBenchmarksPage &&
            <>
              <NavLink to="/comparison" activeClassName={styles.activeLink}>Comparison Profile</NavLink>
              <NavLink to="/archive" activeClassName={styles.activeLink}>Archive</NavLink>
              <NavLink to="/tutorials" activeClassName={styles.activeLink}>Tutorials</NavLink>
            </>
          }
        </div>
        <div className={styles.toggles}>
          {isNotOnBenchmarksPage &&
            <>
              <ComparisonToggle />
              <ThemeSwitch />
            </>
          }
          <UserMenu />
        </div>
      </div>
    ) : (
      <div className={styles.public}>
        {isNotOnHomePage &&
          <Link className={styles.standard} to="/contact">Contact Us</Link>
        }
        <div className={styles.buttons}>
          <Button to="/register/one" size="xs" color="secondary">REGISTER</Button>
          <Button to="/sign-in" size="xs" color="primary">SIGN IN</Button>
        </div>
      </div>
    )}
  </header>

export default Header;
