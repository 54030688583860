export const ADD_STATE = "ADD_STATE";
export const REMOVE_STATE = "REMOVE_STATE";
export const SET_STATES = "SET_STATES";

export const addState = (insurance, state) => ({type: ADD_STATE, payload: {insurance, state}})
export const removeState = (insurance, state) => ({type: REMOVE_STATE, payload: {insurance, state}})
export const setStates = (insurance, states) => ({type: SET_STATES, payload: {insurance, states}});

const initialState = {
  medical: {},
  dental: {}
};

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case ADD_STATE:
      return {
        ...previousState,
        [payload.insurance]: {
          ...previousState[payload.insurance],
          [payload.state]: true
        }
      }
      break;
    case REMOVE_STATE:
      let { [payload.state]: value, ...rest } = previousState[payload.insurance];
      return {
        ...previousState,
        [payload.insurance]: rest
      };
      break;
    case SET_STATES:
      return {
        ...previousState,
        [payload.insurance]: payload.states
      }
      break;
    default:
      return previousState;
  }
}
