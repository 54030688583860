export const OPEN_BAR_GRAPH = "OPEN_BAR_GRAPH";
export const CLOSE_BAR_GRAPH = "CLOSE_BAR_GRAPH";

export const openBarGraph = (row, id) => ({ type: OPEN_BAR_GRAPH, payload: { row, id } })
export const closeBarGraph = () => ({type: CLOSE_BAR_GRAPH});

const initialState = { open: null };

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case "TOGGLE_INSURANCE_TYPE":
      return {
        open: null
      }
    case OPEN_BAR_GRAPH:
      return {
        open: payload
      }
    case CLOSE_BAR_GRAPH:
      return {
        open: null
      }
    default:
      return previousState;
  }
}
