import { all } from 'redux-saga/effects';

import userSagas from './user';
import statSagas from './data';
import comparisonSagas from './comparison';
import pdfSagas from './pdf';
import optionsSagas from './options';

export default function* rootSaga() {
  yield all([
    userSagas(),
    statSagas(),
    comparisonSagas(),
    pdfSagas(),
    optionsSagas()
  ]);
}
