import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import ProfileIcon from '../SVGComponents/Profile';

const UserBanner = ({company, name, avatar, secondary}) =>
  <div className={secondary ? styles.container+" "+styles.secondary : styles.container}>
    <div>
      <div className={styles.company}>{company}</div>
      <div className={styles.name}>{name}</div>
    </div>
    <Link to="/profile" className={styles.avatarWrapper}>
      {avatar ? <img src={avatar} className={styles.image} /> : <ProfileIcon className={styles.placeholder} />}
    </Link>
  </div>

export default UserBanner;
