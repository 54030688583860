export const OPTIONS_REQUEST = "OPTIONS_REQUEST";
export const OPTIONS_REQUEST__SUCCESS = "OPTIONS_REQUEST__SUCCESS";
export const OPTIONS_REQUEST__FAILURE = "OPTIONS_REQUEST__FAILURE";

export const getBenchmarkOptions = () => ({type: OPTIONS_REQUEST})

const initialState = {
  regions: [],
  group: [],
  industry: [],
  major_industry: [],
  products: {
    medical: [],
    dental: []
  },
  filter: {
    medical: [],
    dental: []
  },
  states: []
};

export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case OPTIONS_REQUEST__SUCCESS:
      return payload
      break;
    case "LOGIN_REQUEST__SUCCESS":
      return payload.options
      break;
    case "LOGOUT":
      return initialState;
      break;
    default:
      return previousState;
  }
}
