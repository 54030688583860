import { connect } from 'react-redux';
import ComparisonToggle from '../components/ComparisonToggle';
import { toggleComparison } from '../store/display';


export default connect(
  ({display}) => ({
    active: display.showComparison
  }),
  { toggleComparison }
)(ComparisonToggle);
