export const SELECT_CLIENT = "SELECT_CLIENT";
export const SET_ADD_CLIENT_INPUT = "SET_ADD_CLIENT_INPUT";
export const GET_CLIENT_COMPARISON_PROFILE__SUCCESS = "GET_CLIENT_COMPARISON_PROFILE__SUCCESS";
export const SET_COMPARISON_VALUE = "SET_COMPARISON_VALUE";
export const RESET_COMPARISON_VALUES = "RESET_COMPARISON_VALUES";
export const SAVE_COMPARISON_VALUES = "SAVE_COMPARISON_VALUES";
export const CLEAR_COMPARISON_STATE = "CLEAR_COMPARISON_STATE";

export const selectClient = (client) => ({type: SELECT_CLIENT, payload: client});
export const handleInputChange = ({target}) => ({type: SET_ADD_CLIENT_INPUT, payload: target.value});
export const setComparisonValue = (type, name, value) => ({type: SET_COMPARISON_VALUE, payload: {type, name, value}})
export const reset = () => ({type: RESET_COMPARISON_VALUES});
export const save = () => ({type: SAVE_COMPARISON_VALUES});
export const clear = () => ({type: CLEAR_COMPARISON_STATE});

const initialState = {
  selector: null,
  input: '',
  company: null,
  company_quote_rx: {},
  company_quote: {},
  company_quote_dental: {}
};


export default function(previousState = initialState, { type, payload }){
  switch (type) {
    case SET_ADD_CLIENT_INPUT:
      if(previousState.selector){
        return {
          ...initialState,
          input: payload
        }
      } else {
        return {
          ...previousState,
          input: payload
        }
      }
    case SELECT_CLIENT:
      return {
        ...initialState,
        selector: payload
      }
      break;
    case RESET_COMPARISON_VALUES:
      if(previousState.company){
        return {
          ...previousState,
          company_quote_rx: previousState.company.company_quote_rx,
          company_quote: previousState.company.company_quote,
          company_quote_dental: previousState.company.company_quote_dental
        }
      } else {
        return {
          ...previousState,
          company_quote_rx: {},
          company_quote: {},
          company_quote_dental: {}
        }
      }
    case SET_COMPARISON_VALUE:
      return {
        ...previousState,
        [payload.type]: {
          ...previousState[payload.type],
          [payload.name]: payload.value
        }
      }
    case "TOGGLE_INSURANCE_TYPE":
      return initialState;
    case GET_CLIENT_COMPARISON_PROFILE__SUCCESS:
      return {
        ...previousState,
        company: payload,
        company_quote_rx: payload.company_quote_rx ? payload.company_quote_rx : {},
        company_quote: payload.company_quote ? payload.company_quote : {},
        company_quote_dental: payload.company_quote_dental ? payload.company_quote_dental : {}
      }
      break;
    case CLEAR_COMPARISON_STATE:
      return initialState;
    default:
      return previousState;
  }
}
